import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import publics from "./public";
import user from "./user";
import admin from "./admin";

export default combineReducers({
    auth,
    message,
    publics,
    user,
    admin
});