import "./ReviewCard.scss";

function ReviewCard(props){
    return (
        <div className={`review-card ${props.extra ? "extra" : ""}`}>
            <img alt={props.value.name} src={require(`../../../assets/icons/reviews/${props.value.logo}`)} />
            <span className="review">{`"${props.value.review}"`}</span>
            <span className="reviewer">{props.value.name}</span>
            <span className="position">{props.value.position}</span>
        </div>
    )
}

export default ReviewCard;