import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./ResetModal.scss";
import { useModal } from "../../../Contexts/ModalContext";
import LoginModal from "../LoginModal/LoginModal";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register, reset } from "../../../Actions/auth";
import TwoFactorModal from "../TwoFactorModal/TwoFactorModal";
import { useNavigate } from "react-router-dom";

function ResetModal(props) {
    const { handleSubmit, control, getValues, formState: { errors } } = useForm();

    const { openModal, closeModal } = useModal();

    const [isLoading, setLoading] = useState(false);
    const { message } = useSelector(state => state.message);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpenLogin = () => {
        closeModal();
        openModal(LoginModal);
    }

    const onSubmit = (data) => {
        setLoading(true);

        data.email = props.email;
        data.token = props.token;

        dispatch(reset(data))
            .then(() => {
                closeModal();
                navigate('/dashboard');
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <PopupModal>
            <div className="register-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Reset password</h3>
                    <p className="modal-subtitle">Please enter your new details to continue.</p>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-item w-100">
                            <label>Password</label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 6,
                                        message: 'Password must be at least 6 characters',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="password" {...field} placeholder="Enter your password..." />
                                )}
                            />
                            <p className="error">{errors.password?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <label>Confirm Password</label>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{
                                    required: 'Confirm Password is required',
                                    validate: (value) =>
                                        value === getValues('password') ||
                                        'Passwords do not match',
                                }}
                                render={({ field }) => (
                                    <input type="password" {...field} placeholder="Enter your password confirmation..." />
                                )}
                            />
                            <p className="error">{errors.confirmPassword?.message}</p>
                        </div>
                        {
                            message && <p className="error">
                                {message}
                            </p>
                        }
                        <button id="submit-form" type="submit" disabled={isLoading}>
                            {isLoading && <div className="loader"></div>}
                            Reset Password
                        </button>
                    </form>
                </div>
                <div className="modal-footer">
                    <hr />
                    <p>Already have an account? Click <a className="text-underline" onClick={handleOpenLogin}>here</a>.</p>
                </div>
            </div>
        </PopupModal>
    )
}

export default ResetModal;