import ReactSlider from "react-slider";

import "./Slider.scss"

function Slider(props){
    return (
        <ReactSlider 
        className="custom-slider" 
        trackClassName="custom-slider-track" 
        thumbClassName="custom-slider-thumb"
        disabled={props.disabled}
        min={props.min}
        max={props.max}
        step={props.step}
        value={props.value}
        onChange={(value) => props.onChange(value)}
        />
    )
}

export default Slider;