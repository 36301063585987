import PopupModal from "../../General/PopupModal/PopupModal";
import OrderDetails from "../OrderDetails/OrderDetails";

import "./OrderModal.scss";

function OrderModal(props) {
    return (
        <PopupModal>
            <div className="order-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Active Plans</h3>
                </div>
                <div className="modal-body">
                    {
                        props.orders?.map(order => (
                            <OrderDetails key={order.id} order={order} server={props.server}/>
                        ))
                    }
                </div>
            </div>
        </PopupModal>
    )
}

export default OrderModal;