import GenerateResult from "../../../Components/Dashboard/GenerateResult/GenerateResult";
import OrderDetails from "../../../Components/Dashboard/OrderDetails/OrderDetails";
import OrderSelector from "../../../Components/Dashboard/OrderSelector/OrderSelector";
import "./Isp.scss"

import products from '../../../data/products';
import ProxyCard from "../../../Components/Landing/ProxyCard/ProxyCard";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PROXY_ISP } from "../../../data/product";
import userService from "../../../Services/user.service";
import { SET_ISPS } from "../../../Actions/type";
import MultiOrder from "../../../Components/Dashboard/MultiOrder/MultiOrder";

function Isp() {
    let width = window.innerWidth;

    const dispatch = useDispatch();
    const { plans, isps } = useSelector(state => state.user);

    const getOrders = () => plans?.filter(x => x.productId == PROXY_ISP)

    return (
        <>
            {
                !getOrders() || getOrders()?.length <= 0 ?
                    <>
                        <div className="empty-page-container">
                            <div className="empty-container">
                                <img src={require(`../../../assets/icons/locked.svg`).default} />
                                <div className="notice">
                                    <span>You don't have any <b>UK ISP</b> plans</span>
                                    <span>You can purchase one <Link to="/dashboard">here</Link>.</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="dashboard-page isp-page-container">
                            <div className="left-container">
                                <div className="data-container">
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Proxy List</span>
                                    </div>
                                    <OrderSelector title="UK ISP Orders" subtitle="Generate via Order ID below to access your plan" orders={getOrders()} onGenerate={(order) => userService.getIsps(order).then((data) => dispatch({
                                        type: SET_ISPS,
                                        payload: data,
                                    }))} />
                                    {
                                        width < 992 ? (
                                            <div className="result-container">
                                                <GenerateResult proxies={isps}/>
                                            </div>
                                        ) : <></>
                                    }
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Active Plans</span>
                                    </div>
                                    {getOrders() && getOrders().length > 1 ? <MultiOrder orders={getOrders()}/> : <OrderDetails order={getOrders()[0]} />}
                                </div>
                                {
                                    width >= 992 ? (
                                        <div className="result-container">
                                            <GenerateResult proxies={isps}/>
                                        </div>
                                    ) : <></>
                                }
                            </div>
                            <div className="purchase-container">
                                <div className="subtitle-container">
                                    <span className="dashboard-subtitle">Purchase</span>
                                </div>
                                <ProxyCard value={products.proxies[0]} />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Isp;