import { useDispatch, useSelector } from "react-redux";
import Card from "../../General/Card/Card";

import "./GenerateResult.scss"
import { useState } from "react";
import { SET_PROXIES, SET_RESIDATA } from "../../../Actions/type";
import userService from "../../../Services/user.service";

function GenerateResult({ proxies, allowReset }) {

    const dispatch = useDispatch();

    const { resiData } = useSelector(state => state.user)

    const [isCopyLoading, setCopyLoading] = useState(false);
    const [isDownloadLoading, setDownloadLoading] = useState(false);
    const [isResetting, setResetting] = useState(false);

    const handleCopy = () => {
        if (proxies?.length > 0) {
            setCopyLoading(true);
            navigator.clipboard.writeText(proxies?.join('\r\n'));
            setTimeout(() => setCopyLoading(false), 600);
        }
    }

    const handleDownload = () => {
        if (proxies?.length > 0) {
            setDownloadLoading(true);
            const element = document.createElement("a");
            const file = new Blob([proxies?.join('\r\n')], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = "proxies.txt";
            document.body.appendChild(element);
            element.click();
            setTimeout(() => setDownloadLoading(false), 1000);
        }
    }

    const handleReset = () => {
        setResetting(true);
        userService.submitResiReset()
            .then((data) => {
                dispatch({
                    type: SET_RESIDATA,
                    payload: { ...resiData, password: data.password },
                });

                dispatch({
                    type: SET_PROXIES,
                    payload: [],
                })
            }).finally(() => {
                setResetting(false);
            });
    };

    return (
        <Card classes="result-card">
            <div className="card-body">
                <form>
                    <div className="form-item w-100">
                        <textarea readOnly placeholder="Click generate to populate the field" value={proxies?.join('\r\n')} />
                    </div>
                </form>
                <div className="button-row">
                    <button onClick={handleCopy}>
                        {isCopyLoading && <div className="loader" style={{ width: "14px", height: "14px" }}></div>}
                        Copy
                    </button>
                    <button onClick={handleDownload}>
                        {isDownloadLoading && <div className="loader" style={{ width: "14px", height: "14px" }}></div>}
                        Download
                    </button>
                    {
                        allowReset && <button onClick={handleReset}>
                            {isResetting && <div className="loader" style={{ width: "14px", height: "14px" }}></div>}
                            Reset Password
                        </button>
                    }
                </div>
            </div>
        </Card>
    )
}

export default GenerateResult;