var reviews = [
    {
        logo: "midnite.svg",
        review: "Primed Proxies has been a game-changer for me at Midnite. As the Head Bookmaker for e-sports, I rely on their proxies to scrape odds from various bookmakers and compile them effectively. Their service not only delivers the data I need but their exceptional customer service has been a lifeline in ensuring everything runs smoothly.",
        name: "Kai Weymes",
        position: "Head eSports Bookmaker, Midnite"
    },
    {
        logo: "cilly.svg",
        review: "Primed’s fast and stable proxies let me access, collaborate, and transfer design assets easily. Their support team is always quick and helpful.",
        name: "Alexander Cillán",
        position: "Owner, Cillysen Graphics"
    },
    {
        logo: "detailing.svg",
        review: "Our detailing business relies heavily on effectively managing our advertising accounts to reach our target audience. Thanks to the exceptional support of Primed and their powerful mobile proxies, we've been able to achieve remarkable results that have significantly boosted our revenue.",
        name: "Joe Gora",
        position: "Director, JNJ Detailing"
    },
    {
        logo: "mackenzies.svg",
        review: "As a restaurant owner, staying ahead of the competition and truly understanding our customers' preferences is paramount. By using Primed Proxies vast high-quality infrastructure, we were able to gather key customer data points from our mobile ordering app and discover specific trends. This allowed us to focus our marketing campaign on the things that mattered.",
        name: "Gael Mackenzie",
        position: "Owner, Mackenzies Bar & Restaurants"
    },
    {
        logo: "helptrades.svg",
        review: "Primed Proxies helps us get vital market data easily, boosting our online presence and strategy.",
        name: "Anthony Woolcock",
        position: "Director, HelpTrades",
        extra: true
    },
    {
        logo: "freshness.svg",
        review: "In our pursuit of growing as a company within a specific niche, Primed Proxies emerged as an invaluable partner. Their reliable solutions, competitive pricing, and exceptional customer service have transformed our infrastructure seamlessly. Highly recommended for quality and value!",
        name: "Wouter Janssens",
        position: "CEO, Freshness"
    },
];

export default reviews;
