import "./ImageCard.scss";

function ImageCard(props) {
    return (
        <div className="image-card">
            <div className="image-container">
                <img src={props.image} alt={props.title} width={props.width} style={{padding: props.margin}}/>
            </div>
            <span className="image-title">{props.title}</span>
            <p className="image-description">{props.description}</p>
        </div>
    )
}

export default ImageCard;