import { useSelector } from "react-redux";
import Card from "../../General/Card/Card";

import "./ServerResult.scss"
import { useState } from "react";

function ServerResult() {

    const { server } = useSelector(state => state.user);

    const [isCopyLoading, setCopyLoading] = useState(false);
    const [isDownloadLoading, setDownloadLoading] = useState(false);

    const handleCopy = () => {
        if (server) {
            setCopyLoading(true);
            navigator.clipboard.writeText(server.password);
            setTimeout(() => setCopyLoading(false), 600);
        }
    }

    const handleDownload = () => {
        if (server) {
            setDownloadLoading(true);

            const rdpSettings = `
            screen mode id:i:2
use multimon:i:0
desktopwidth:i:5120
desktopheight:i:1440
session bpp:i:32
winposstr:s:0,1,2008,6,5028,1360
compression:i:1
keyboardhook:i:2
audiocapturemode:i:0
videoplaybackmode:i:1
connection type:i:7
networkautodetect:i:1
bandwidthautodetect:i:1
displayconnectionbar:i:1
enableworkspacereconnect:i:0
disable wallpaper:i:0
allow font smoothing:i:0
allow desktop composition:i:0
disable full window drag:i:1
disable menu anims:i:1
disable themes:i:0
disable cursor setting:i:0
bitmapcachepersistenable:i:1
full address:s:${server.ip}
audiomode:i:0
redirectprinters:i:1
redirectlocation:i:0
redirectcomports:i:0
redirectsmartcards:i:1
redirectwebauthn:i:1
redirectclipboard:i:1
redirectposdevices:i:0
autoreconnection enabled:i:1
authentication level:i:2
prompt for credentials:i:0
negotiate security layer:i:1
remoteapplicationmode:i:0
alternate shell:s:
shell working directory:s:
gatewayhostname:s:
gatewayusagemethod:i:4
gatewaycredentialssource:i:4
gatewayprofileusagemethod:i:0
promptcredentialonce:i:0
gatewaybrokeringtype:i:0
use redirection server name:i:0
rdgiskdcproxy:i:0
kdcproxyname:s:
enablerdsaadauth:i:0
username:s:${server.username}`;

            const element = document.createElement("a");
            const file = new Blob([rdpSettings.replaceAll("\t", "").replaceAll("  ", " ").trim()], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = "primed-connect.rdp";
            document.body.appendChild(element);
            element.click();
            setTimeout(() => setDownloadLoading(false), 1000);
        }
    }

    return (
        <Card classes="server-result-card">
            <div className="card-body">
                <form>
                    <div className="form-item w-100">
                        <label>IP Address</label>
                        <input readOnly placeholder="..." value={server?.ip} />
                    </div>
                    <div className="form-item w-50">
                        <label>User</label>
                        <input readOnly placeholder="..." value={server?.username} />
                    </div>
                    <div className="form-item w-50">
                        <label>Password</label>
                        <input readOnly placeholder="..." value={server?.password} />
                    </div>
                </form>
                <div className="button-row">
                    <button onClick={handleCopy}>
                        {isCopyLoading && <div className="loader" style={{ width: "14px", height: "14px" }}></div>}
                        Copy Password
                    </button>
                    <button className="refresh" onClick={handleDownload}>
                        {isDownloadLoading && <div className="loader" style={{ width: "14px", height: "14px" }}></div>}
                        Download
                    </button>
                </div>
            </div>
        </Card>
    )
}

export default ServerResult;