import { Link } from "react-router-dom";
import Card from "../../General/Card/Card";

import "./IspCard.scss"
import { useSelector } from "react-redux";
import { PROXY_ISP, PROXY_MOBILE } from "../../../data/product";
import utils from "../../../Utils/utils";

function IspCard() {

    const { plans } = useSelector(state => state.user);

    const getOrders = () => plans?.filter(x => x.productId == PROXY_ISP)

    return (
        <Card classes="isp-card">
            <div className="card-header">
                <div className="card-icon">
                    <img src={require(`../../../assets/icons/isp-filled.svg`).default} />
                </div>
                <span className="card-title">UK ISP</span>
                {
                    getOrders()?.length > 0 && <div className="expiry-container">
                        <span>{getOrders() && getOrders().length > 1 ? "MULTIPLE PLANS" : `${utils.getDaysLeft(getOrders()[0].expiry)} DAYS LEFT`}</span>
                    </div>
                }
            </div>
            <div className="card-body">
                {
                    !getOrders() || getOrders().length <= 0 ?
                        <>
                            <img src={require(`../../../assets/icons/locked.svg`).default} />
                            <div className="notice">
                                <span>You don't have any <b>UK ISP</b> plans</span>
                                <span>You can purchase one below.</span>
                            </div>
                        </>
                        :
                        <>
                            <h1>{getOrders().reduce((partialSum, a) => partialSum + +a.properties["quantity"], 0)}</h1>
                            <div className="notice">
                                <span>Active proxies available.</span>
                                <span>Click <Link to="/dashboard/isp">here</Link> to view your proxy list.</span>
                            </div>
                        </>
                }
            </div>
        </Card>
    )
}

export default IspCard;