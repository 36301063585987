import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./ForgotModal.scss";
import { useModal } from "../../../Contexts/ModalContext";
import RegisterModal from "../RegisterModal/RegisterModal";
import TwoFactorModal from "../TwoFactorModal/TwoFactorModal";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../../Actions/auth";
import { useEffect, useRef, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useNavigate } from "react-router-dom";
import purchaseService from "../../../Services/purchase.service";
import authService from "../../../Services/auth.service";

function ForgotModal(props) {
    const turnstile = useTurnstile();
    const turnstileRef = useRef(null);
    const [token, setToken] = useState();

    const { handleSubmit, control, reset, formState: { errors } } = useForm();

    const { openModal, closeModal } = useModal();

    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpenRegister = () => {
        closeModal();
        openModal(RegisterModal);
    }

    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    const onSubmit = (data) => {
        setLoading(true);

        data.token = token;

        authService.forgot(data).then(() => {
           setLoading(false);
           setEmail(data.email);

           reset({ email: "" });
        }).catch(() => {
            setLoading(false);
            setMessage("There was an issue processing your request, try again later.");
        }).finally(() => {
            turnstile.reset(turnstileRef.current);
        });
    };

    useEffect(() => {
        dispatch(logout())
    }, []);

    return (
        <PopupModal>
            <div className="forgot-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Reset your password</h3>
                    <p className="modal-subtitle">Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-item w-100">
                            <label>Email</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: 'Invalid email',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="text" {...field} placeholder="Enter your email address..." />
                                )}
                            />
                            <p className="error">{errors.email?.message}</p>
                        </div>
                        <Turnstile userRef={turnstileRef} sitekey={process.env.REACT_APP_TURNSTILE_KEY} onVerify={setToken} />
                        {
                            message && <p className="error">
                                {message}
                            </p>
                        }
                        <button id="submit-form" type="submit" disabled={isLoading || !token}>
                            {isLoading && <div className="loader"></div>}
                            Continue
                        </button>
                        {email && <div className="flex-row" style={{justifyContent: "center", textAlign: "center"}}>
                            <p>A reset confirmation has been sent to: {email}</p>
                        </div>
                        }
                    </form>
                </div>
                <div className="modal-footer">
                    <hr />
                    <p>Don't have an account yet? Get started <a className="text-underline" onClick={handleOpenRegister}>here</a>.</p>
                </div>
            </div>
        </PopupModal>
    )
}

export default ForgotModal;