import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";
import AuthCode from 'react-auth-code-input';

import "./TwoFactorModal.scss";
import { useModal } from "../../../Contexts/ModalContext";
import RegisterModal from "../RegisterModal/RegisterModal";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirm, resendCode } from "../../../Actions/auth";
import { useNavigate } from "react-router-dom";

function TwoFactorModal(props) {
    const authRef = useRef();

    const { handleSubmit, control, formState: { errors } } = useForm();

    const [result, setResult] = useState();

    const [isLoading, setLoading] = useState(false);
    const [isResending, setResending] = useState(false);

    const { message } = useSelector(state => state.message);
    const [resendError, setResendError] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOnChange = (res) => {
        setResult(res);

        if (res.length == 6)
            onSubmit(res);
    };

    const { openModal, closeModal } = useModal();

    const handleOpenRegister = () => {
        closeModal();
        openModal(RegisterModal);
    }

    const onSubmit = (data) => {
        setLoading(true);

        dispatch(confirm(props.email, props.password, data ?? result))
            .then(() => {
                closeModal();
                navigate("/dashboard");
            })
            .catch(() => {
                setLoading(false);

                authRef.current?.clear();
            });
    };

    const onSubmitResend = () => {
        setResending(true);
        setResendError();

        let data = {
            email: props.email,
            password: props.password
        };

        dispatch(resendCode(data))
            .then(() => {
                setResending(false);

                setResendError("A new code has been sent to: " + props.email);
            }).catch(() => {
                setResending(false);

                setResendError("Failed sending new code, try again later!")
            });
    }

    return (
        <PopupModal>
            <div className="twofactor-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Thanks for signing up!</h3>
                    <p className="modal-subtitle">Please, verify your account with the code we've sent you via email. Make sure to check your spam / junk folder.</p>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-item w-100">
                            <label>2FA Code</label>
                            <AuthCode ref={authRef} onChange={handleOnChange} allowedCharacters='numeric' containerClassName="twofactor-container" />
                        </div>
                        {
                            message && <p className="error">
                                {message}
                            </p>
                        }
                        <button id="submit-form" type="submit" disabled={isLoading}>
                            {isLoading && <div className="loader"></div>}
                            Verify account
                        </button>
                    </form>
                </div>
                <div className="modal-footer">
                    <hr />
                    <p className="flex-row">
                        Didn't receive a code? Click
                        <button className="text-underline" onClick={onSubmitResend} disabled={isResending}>
                            {isResending && <span className="loader"></span>}
                            here
                        </button>
                        to resend.
                    </p>
                    {
                        resendError && <p className="error">
                            {resendError}
                        </p>
                    }
                </div>
            </div>
        </PopupModal>
    )
}

export default TwoFactorModal;