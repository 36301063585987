import "./DataCard.scss";

function DataCard({ title, data, subTitle, classes }) {

    return (
        <div className={`card data-card ${classes}`}>
            <div className="card-body">
                <div className="centered">
                    <span className="value">{data}</span>
                    <span className="title">{title}</span>
                    <span className="subTitle">{subTitle}</span>
                </div>
            </div>
        </div>
    )
}

export default DataCard;