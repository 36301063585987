import { useModal } from "../../../Contexts/ModalContext";
import Card from "../../General/Card/Card"
import OrderModal from "../OrderModal/OrderModal";
import "./MultiOrder.scss"

function MultiOrder({orders, server}) {

    const { openModal } = useModal();

    const handleOpenModal = () => {
        openModal(OrderModal, {orders: orders, server: server})
    };

    return (
        <Card classes="multi-order-details-card">
            <div className="card-header">
                <span className="card-title">Multiple Plans Available</span>
            </div>
            <div className="card-body">
                <div className="card-content">
                    <div className="order-details-container">
                        <div className="detail-item">
                            <span className="title">Total Plans</span>
                            <span className="value">{orders?.length ?? 0}</span>
                        </div>
                        <div className="detail-item">
                            <span className="title">First Expiry</span>
                            {orders && <span className="value">{new Date(orders?.sort(x => new Date(x.expiry))[0].expiry).toLocaleString()}</span>}
                        </div>
                    </div>
                    <button id="submit-form" onClick={handleOpenModal}>View All</button>
                </div>
            </div>
        </Card>
    )
}

export default MultiOrder;