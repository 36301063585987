import { useSelector } from "react-redux";
import GenerateResi from "../../../Components/Dashboard/GenerateResi/GenerateResi";
import GenerateResult from "../../../Components/Dashboard/GenerateResult/GenerateResult";
import ResiCard from "../../../Components/Dashboard/ResiCard/ResiCard";
import ProxyCard from "../../../Components/Landing/ProxyCard/ProxyCard";

import products from '../../../data/products';

import "./Residential.scss"
import { Link } from "react-router-dom";
import { PROXY_RESI } from "../../../data/product";

function Residential() {

    const { plans, proxies } = useSelector(state => state.user);

    const getOrder = () => plans?.find(x => x.productId == PROXY_RESI);

    return (
        <>
            {
                !getOrder() ?
                    <>
                        <div className="empty-page-container">
                            <div className="empty-container">
                                <img src={require(`../../../assets/icons/locked.svg`).default} />
                                <div className="notice">
                                    <span>You don't have any <b>Residential</b> plans</span>
                                    <span>You can purchase one <Link to="/dashboard">here</Link>.</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="dashboard-page residential-page-container">
                            <div className="subtitle-container">
                                <span className="dashboard-subtitle">Generate Proxies</span>
                            </div>
                            <div className="generate-container">
                                <GenerateResi />
                                <GenerateResult proxies={proxies} allowReset={true}/>
                            </div>
                            <div className="topup-status-container">
                                <div className="topup-container">
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">TOP-UP RESIDENTIAL DATA</span>
                                    </div>
                                    <ProxyCard value={products.proxies[1]} buyText="Top-up" />
                                </div>
                                <div className="status-container">
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Active Plans</span>
                                    </div>
                                    <ResiCard extended/>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Residential;