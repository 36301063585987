import "./ProxyCard.scss";

import Slider from "../Slider/Slider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../General/Loading/Loading";
import { useModal } from "../../../Contexts/ModalContext";
import LoginModal from "../../Auth/LoginModal/LoginModal";
import purchaseService from "../../../Services/purchase.service";
import publicService from "../../../Services/public.service";

function ProxyCard(props) {
    const [currentAmount, setCurrentAmount] = useState();

    const { prices } = useSelector(state => state.publics);
    const { isLoggedIn } = useSelector(state => state.auth);

    const { openModal } = useModal();

    const [isLoading, setLoading] = useState(false);
    const [isLoadingCoinbase, setLoadingCoinbase] = useState(false);
    const [isFetchingStock, setFetchingStock] = useState(false);

    const [stock, setStock] = useState(-1);

    function handleAmountChange(amount) {
        setCurrentAmount(amount);

        if (amount === product().maximum && props.onReachedMax)
            props.onReachedMax(props.value.name);
    }

    function onToggle() {
        props.onToggle(props.value);
    }

    const handlePurchase = (coinbase) => {
        coinbase ? setLoadingCoinbase(true) : setLoading(true);

        const request = { productId: props.value.id, quantity: currentAmount, coinbase: coinbase };

        if (!isLoggedIn) {
            openModal(LoginModal, request);
            coinbase ? setLoadingCoinbase(false) : setLoading(false);
        } else {
            (coinbase ? purchaseService.purchaseCoinbase(request) : purchaseService.purchase(request)).then((data) => {
                window.location.href = data.url;
            });
        }
    }

    const product = () => prices?.find(x => x.id == props.value.id);

    useEffect(() => {
        if (!currentAmount && product()) {
            setCurrentAmount(product().minimum);
        }
    }, [prices])

    useEffect(() => {
        setFetchingStock(true);
        publicService.getStock(props.value.id).then((data) => {
            setStock(data.availability);
            setFetchingStock(false);
        }).catch(() => {
            setStock(0);
            setFetchingStock(false);
        });
    }, []);

    return (
        <div id={props.value.id} className="proxy-card">
            <div className="proxy-card-header">
                <div className="icon-container">
                    <img alt={props.value.name} src={require(`../../../assets/icons/${props.value.icon}`)} />
                </div>
                <span className="proxy-card-title">{props.value.name}</span>
            </div>
            <div className="proxy-card-body">
                {
                    props.extended ?
                        <span className="proxy-card-description">{props.value.description}</span>
                        : <></>
                }
                <div className="price-container">
                    {
                        !product() ?
                            <Loading height="35px" width="100px" />
                            :
                            <>
                                <span className="price-total">£{(currentAmount ?? 1) * product().pricePerUnit}</span>
                                <span className="price-detail">£{product().pricePerUnit.toFixed(2)} / {product().priceDescription}</span>
                            </>
                    }
                </div>
                <div className="slider-container">
                    {
                        !product() ?
                            <Loading height="35px" width="100%" />
                            :
                            <>
                                <div className="slider-header">
                                    <span>Quantity</span>
                                    <span className="amount">{currentAmount} {currentAmount == 1 ? props.value.unit : props.value.units}</span>
                                </div>
                                <div className="slider-body">
                                    <Slider disabled={isLoading} min={product().minimum} max={product().maximum} step={product().steps} value={currentAmount} onChange={handleAmountChange} />
                                </div>
                            </>
                    }
                </div>
                <div className="buy-button-container">
                    <button className={`${stock < 0 || stock < currentAmount ? "buy-now oos" : "buy-now"}`} onClick={() => handlePurchase(false)} disabled={isLoading || stock < currentAmount}>
                        {(isLoading || isFetchingStock) && <div className="loader"></div>}
                        {stock < 0 ? "Fetching availability" : (stock < currentAmount ? "Not enough in stock" : props.buyText ?? (props.showDetails ? "Purchase" : "Buy Now"))}
                    </button>
                    {
                        props.value.coinbase && !isFetchingStock &&
                        <button className={`${stock < 0 || stock < currentAmount ? "buy-now oos" : "buy-now"} coinbase`} onClick={() => handlePurchase(true)} disabled={isLoadingCoinbase || stock < currentAmount}>
                            {(isLoadingCoinbase || isFetchingStock) && <div className="loader"></div>}
                            <img src={require('../../../assets/icons/coinbase.svg').default} />
                            {stock < 0 ? "Fetching availability" : (stock < currentAmount ? "Not enough in stock" : props.buyText ? `${props.buyText} with Coinbase` : (props.showDetails ? "Purchase" : "Pay with Coinbase"))}
                        </button>
                    }
                </div>
                {
                    props.extended && props.onToggle ?
                        <button className="show-details" onClick={onToggle}>{props.showDetails ? "Hide Product Details" : "Show Product Details"}</button>
                        : <></>
                }
            </div>
            <div className="proxy-card-footer">
                <span className="details-title">Features</span>
                <div className="details-container">
                    {
                        props.value.details.keypoints.map((point) => (
                            <div key={point.title} className="details-row">
                                <img src={require('../../../assets/icons/checkmark-2.svg').default} />
                                <span>{point.title}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ProxyCard;