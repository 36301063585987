import { Controller, useForm } from "react-hook-form";
import Card from "../../General/Card/Card"
import "./OrderSelector.scss"
import { useState } from "react";

function OrderSelector({ title, subtitle, orders, onGenerate }) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [isLoading, setLoading] = useState(false);

    const onSubmit = (data) => {
        if (onGenerate){
            setLoading(true);

            onGenerate(data.order).then((data) => {           
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
    }

    return (
        <Card classes="order-selector-card">
            <div className="card-header">
                <span className="card-title">{title}</span>
                <span className="card-subtitle">{subtitle}</span>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-item w-100">
                        <label>Order ID</label>
                        <Controller
                            name="order"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => (
                                <select {...field} required>
                                    <option value="" disabled hidden>Select an order</option>
                                    {
                                        orders?.map(order => <option key={order.id} value={order.id}>{order.id}</option>)
                                    }
                                </select>
                            )}
                        />
                    </div>
                    <button id="submit-form" type="submit" disabled={isLoading}>
                        {isLoading && <div className="loader" style={{width: "16px", height: "16px"}}></div>}
                        Generate
                    </button>
                </form>
            </div>
        </Card>
    )
}

export default OrderSelector;