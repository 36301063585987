import api from "./api";

class AuthService {

    login(email, password, token) {
        return api
            .post("/authentication/login", { email, password, token })
            .then((response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            }));
    }

    confirm(email, password, code) {
        return api
            .post("/authentication/confirm", { email, password, code })
            .then((response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            }));
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(data) {
        return api
            .post("/authentication/register", data);
    }

    reset(data) {
        return api
            .post("/authentication/reset", data)
            .then((response => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            }));
    }


    forgot(data) {
        return api
            .post("/authentication/forgot", data);
    }

    resendCode(data) {
        return api.post("/authentication/resend", data);
    }

}

export default new AuthService();