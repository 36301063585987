import { SET_PRICES } from "../Actions/type";

const initialState = {
    prices: undefined
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PRICES:
      return { prices: payload };

    default:
      return state;
  }
}