import authHeader from './auth-header';
import api from './api';

class AdminService {

    getAnalytics() {
        return api.get('/admin/analytics', { headers: authHeader() })
            .then((response) => response.data);
    }

    createBlogPost(path, data) {
        var body = {
            path: path,
            data: data
        };

        return api.post('/admin/blog', body, { headers: authHeader() })
            .then((response) => response.data);
    }

    updateBlogPost(post) {
        return api.patch('/admin/blog', post, { headers: authHeader() })
            .then((response) => response.data);
    }

    deleteBlogPost(id) {
        return api.delete('/admin/blog/' + id, { headers: authHeader() })
            .then((response) => response.data);
    }

    getPosts() {
        return api.get('/admin/blog/posts', { headers: authHeader() })
            .then((response) => response.data);
    }

    getAllIsps() {
        return api.get('/admin/isp/all', { headers: authHeader() })
            .then((response) => response.data);
    }

    getUsers(page, search) {
        return api.get(`/admin/users?page=${page}&search=${search ?? ""}`, { headers: authHeader() })
            .then((response) => response.data);
    }

    getUser(id) {
        return api.get(`/admin/users/` + id, { headers: authHeader() })
            .then((response) => response.data);
    }

    getResiData(id) {
        return api.get(`/admin/users/resi/` + id, { headers: authHeader() })
            .then((response) => response.data);
    }

    createLink(data) {
        return api.post(`/admin/users/link`, data, { headers: authHeader() })
            .then((response) => response.data);
    }
}

export default new AdminService();