import React from 'react';

const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref} // We need a reference to this element to load the TrustBox in the effect.
            className="trustpilot-widget" // Renamed this to className.
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="65252186c80a65c2c28bc198"
            data-style-width="400px"
            data-style-height="24px"
            data-min-review-count="50" 
            data-without-reviews-preferred-string-id="1" 
            data-style-alignment="center"
        >
            <a href="https://uk.trustpilot.com/review/primedproxies.com" target="_blank" rel="noopener"/>
        </div>
    );
};

export default TrustBox;