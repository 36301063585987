import userService from "../Services/user.service";
import { PROXY_RESI } from "../data/product";
import { SET_DAILY_ANALYTICS, SET_MONTHLY_ANALYTICS, SET_PLANS, SET_RESIDATA } from "./type";

export const getPlans = () => (dispatch) => {
    return userService.getPlans().then(
        (data) => {
            dispatch({
                type: SET_PLANS,
                payload: data,
            });

            if (data.find(x => x.productId == PROXY_RESI)) {
                dispatch(getResiData());
            }

            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
};

export const getResiData = () => (dispatch) => {
    return userService.getResiData().then(
        (data) => {
            dispatch({
                type: SET_RESIDATA,
                payload: data,
            });

            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
}

export const getResiAnalytics = (type) => (dispatch) => {
    return userService.getResiAnalytics(type).then(
        (data) => {
            if (type == "monthly") {
                dispatch({
                    type: SET_MONTHLY_ANALYTICS,
                    payload: data,
                });
            }
            else {
                dispatch({
                    type: SET_DAILY_ANALYTICS,
                    payload: data,
                });
            }
            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
}