import { Controller, useForm } from "react-hook-form";
import Card from "../../General/Card/Card";
import "./GenerateResi.scss"

import genning from "../../../data/genning"
import { useDispatch, useSelector } from "react-redux";
import { SET_PROXIES } from "../../../Actions/type";

function GenerateResi() {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const mapped = {
        'gu': 'Guam', 'gt': 'Guatemala', 'gn': 'Guinea', 'gw': 'Guinea-Bissau', 'gy': 'Guyana',
        'ht': 'Haiti', 'hn': 'Honduras', 'hk': 'Hong Kong', 'hu': 'Hungary', 'is': 'Iceland',
        'in': 'India', 'id': 'Indonesia', 'ir': 'Iran', 'iq': 'Iraq', 'ie': 'Ireland',
        'im': 'Isle of Man', 'il': 'Israel', 'it': 'Italy', 'ci': 'Ivory Coast', 'jm': 'Jamaica',
        'jp': 'Japan', 'je': 'Jersey', 'jo': 'Jordan', 'kz': 'Kazakhstan', 'ke': 'Kenya',
        'xk': 'Kosovo', 'kw': 'Kuwait', 'kg': 'Kyrgyzstan', 'lv': 'Latvia', 'lb': 'Lebanon',
        'ls': 'Lesotho', 'lr': 'Liberia', 'ly': 'Libya', 'li': 'Liechtenstein', 'lt': 'Lithuania',
        'lu': 'Luxembourg', 'mo': 'Macau', 'mk': 'North Macedonia', 'mg': 'Madagascar', 'mw': 'Malawi',
        'my': 'Malaysia', 'mv': 'Maldives', 'kl': 'Kuala Lumpur', 'mt': 'Malta', 'mh': 'Marshall Islands',
        'mr': 'Mauritania', 'mu': 'Mauritius', 'mx': 'Mexico', 'md': 'Moldova', 'mc': 'Monaco',
        'mn': 'Mongolia', 'me': 'Montenegro', 'ma': 'Morocco', 'mz': 'Mozambique', 'mm': 'Myanmar',
        'na': 'Namibia', 'nr': 'Nauru', 'np': 'Nepal', 'nl': 'Netherlands', 'nz': 'New Zealand',
        'nc': 'New Caledonia', 'ni': 'Nicaragua', 'ng': 'Nigeria', 'mp': 'Northern Mariana Islands',
        'no': 'Norway', 'om': 'Oman', 'pk': 'Pakistan', 'pw': 'Palau', 'ps': 'Palestine',
        'pa': 'Panama', 'pg': 'Papua New Guinea', 'py': 'Paraguay', 'pe': 'Peru', 'ph': 'Philippines',
        'pl': 'Poland', 'pt': 'Portugal', 'pr': 'Puerto Rico', 'qa': 'Qatar', 're': 'Réunion',
        'ro': 'Romania', 'ru': 'Russia', 'rw': 'Rwanda', 'kn': 'Saint Kitts and Nevis', 'lc': 'Saint Lucia',
        'vc': 'Saint Vincent and the Grenadines', 'sm': 'San Marino', 'st': 'São Tomé and Príncipe',
        'sa': 'Saudi Arabia', 'sn': 'Senegal', 'rs': 'Serbia', 'sl': 'Sierra Leone', 'sg': 'Singapore',
        'sk': 'Slovakia', 'si': 'Slovenia', 'so': 'Somalia', 'za': 'South Africa', 'kr': 'South Korea',
        'es': 'Spain', 'lk': 'Sri Lanka', 'pm': 'Saint Pierre and Miquelon', 'sd': 'Sudan', 'sr': 'Suriname',
        'se': 'Sweden', 'ch': 'Switzerland', 'sy': 'Syria', 'tw': 'Taiwan', 'th': 'Thailand',
        'tg': 'Togo', 'tt': 'Trinidad and Tobago', 'tn': 'Tunisia', 'tr': 'Turkey', 'tc': 'Turks and Caicos Islands',
        'ug': 'Uganda', 'ua': 'Ukraine', 'ae': 'United Arab Emirates', 'uk': 'United Kingdom', 'us': 'United States',
        'uy': 'Uruguay', 'uz': 'Uzbekistan', 've': 'Venezuela', 'vn': 'Vietnam', 'vg': 'British Virgin Islands',
        'ye': 'Yemen', 'zm': 'Zambia', 'zw': 'Zimbabwe'
    }

    const formats = {
        "Standard": [
            {
                display: "hostname:port:username:password",
                format: "$1:$2:$3:$4"
            }
        ],
        "Custom": [
            {
                display: "username:password@hostname:port",
                format: "$3:$4@$1:$2"
            },
            {
                display: "username:password:hostname:port",
                format: "$3:$4:$1:$2"
            },
            {
                display: "hostname:port@username:password",
                format: "$1:$2@$3:$4"
            },
            {
                display: "http://username:password@hostname:port",
                format: "http://$3:$4@$1:$2"
            }
        ]
    }
    const { resiData } = useSelector(state => state.user)

    const dispatch = useDispatch();

    const onSubmit = (data) => {
        console.log(data);

        const group = genning.find(x => x.children.find(x => x.id == data.pool));
        const pool = group.children.find(x => x.id == data.pool);

        let proxies = [];
        let format = data.format;

        for (let i = 0; i < data.quantity; i++) {
            var p = pool.countryCodes[Math.floor(Math.random() * pool.countryCodes.length)];
            let domain = pool.domain;
            let port = 8888;
            let username = `${resiData.username}-cc-${p.toUpperCase()}-pool-p2p${data.type == "static" ? `-sessionid-${Math.round(Math.random() * 100000000)}-sessiontime-60` : ""}`;
            let password = resiData.password;

            proxies.push(format.replace("$1", domain).replace("$2", port).replace("$3", username).replace("$4", password));
        }

        dispatch({
            type: SET_PROXIES,
            payload: proxies,
        })
    };

    return (
        <Card classes="generate-resi-card">
            <div className="card-header">
                <span className="card-title">Residential Generator</span>
                <span className="card-subtitle">Generate below to access your plan.</span>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-item w-50">
                        <label>Type</label>
                        <Controller
                            name="type"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => (
                                <select {...field} required>
                                    <option value="" disabled hidden>Select a type</option>
                                    <option value="static">Static</option>
                                    <option value="rotate">Rotating</option>
                                </select>
                            )}
                        />
                    </div>
                    <div className="form-item w-50">
                        <label>Quantity</label>
                        <Controller
                            name="quantity"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => <input {...field} type="number" min={1} max={25000} placeholder='Enter quantity' />}
                        />
                    </div>
                    <div className="form-item w-50">
                        <label>Pool</label>
                        <Controller
                            name="pool"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => (
                                <select {...field} required>
                                    <option value="" disabled hidden>Select a proxy pool</option>
                                    {
                                        genning.map((item) =>
                                            <optgroup key={item.id} label={item.group}>
                                                {
                                                    item.children.map(child =>
                                                        <option key={child.id} value={child.id}>
                                                            {child.name == "Unknown Country" ? mapped[child.countryCodes[0]] : child.name}
                                                        </option>
                                                    )
                                                }
                                            </optgroup>
                                        )
                                    }
                                </select>
                            )}
                        />
                    </div>
                    <div className="form-item w-50">
                        <div className="flex-row pos-rel">
                            <label>Format</label>
                            <div className="tooltip-container" tooltip="Generate using the standard format for mostly all applications. Only generate from custom formats if you specifically require that.">
                                <img className="info-icon" src={require(`../../../assets/icons/info.svg`).default} />
                            </div>
                        </div>
                        <Controller
                            name="format"
                            control={control}
                            defaultValue={"$1:$2:$3:$4"}
                            render={({ field }) => (
                                <select {...field} required>
                                    <option value="" disabled hidden>Select a format</option>
                                    {
                                        Object.keys(formats).map(format =>
                                            <optgroup key={format} label={format}>
                                                {
                                                    formats[format].map((child, index) =>
                                                        <option key={index} value={child.format}>
                                                            {child.display}
                                                        </option>)
                                                }
                                            </optgroup>
                                        )
                                    }
                                </select>
                            )}
                        />
                    </div>
                    <button id="submit-form" type="submit" disabled={!resiData}>Generate</button>
                </form>
            </div>
        </Card>
    )
}

export default GenerateResi;
