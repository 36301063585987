import { useForm, Controller } from 'react-hook-form';

import "./ContactForm.scss";
import Turnstile, { useTurnstile } from 'react-turnstile';
import { useRef, useState } from 'react';
import contactService from '../../../Services/contact.service';

function ContactForm() {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const turnstile = useTurnstile();
    const turnstileRef = useRef(null);
    const [token, setToken] = useState();

    const [isLoading, setLoading] = useState(false);

    const [message, setMessage] = useState();
    const [error, setError] = useState();

    const onSubmit = (data) => {
        setLoading(true);
        setError(null);
        setMessage(null);

        data.token = token;

        contactService.submitContact(data)
            .then(() => {
                setMessage("Thank you for your submission, we have successfully received your form!");

                reset({ fullName: "", email: "", phoneNumber: "", companyName: "", message: "" });
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                setError(message);
            })
            .finally(() => {
                turnstile.reset(turnstileRef.current);
                setLoading(false);
            });
    };

    return (
        <div className="contact-form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-item w-50'>
                    <label>Full Name</label>
                    <Controller
                        name="fullName"
                        control={control}
                        rules={{
                            required: 'Full Name is required',
                            maxLength: {
                                value: 50,
                                message: 'Full Name must be less than 50 characters',
                            },
                        }}
                        render={({ field }) => <input {...field} placeholder='Enter your full name...' />}
                    />
                    {errors.fullName && <p className="error">{errors.fullName.message}</p>}
                </div>

                <div className='form-item w-50'>
                    <label>Email Address</label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Email Address is required',
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field }) => <input {...field} type="email" placeholder='Enter your email address...' />}
                    />
                    {errors.email && <p className="error">{errors.email.message}</p>}
                </div>

                <div className='form-item w-50'>
                    <label>Phone Number</label>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            required: 'Phone Number is required',
                        }}
                        render={({ field }) => <input {...field} type="tel" placeholder='Enter your phone number...' />}
                    />
                    {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>}
                </div>

                <div className='form-item w-50'>
                    <label>Company Name</label>
                    <Controller name="companyName" control={control} render={({ field }) => <input {...field} placeholder='Enter your company name...' />} />
                </div>

                <div className='form-item w-100'>
                    <label htmlFor="contact-form-subject">Subject</label>
                    <Controller
                        name="subject"
                        control={control}
                        defaultValue="general"
                        render={({ field }) => (
                            <select {...field} id="contact-form-subject">
                                <option value="general">General Inquiry</option>
                                <option value="support">Customer Support</option>
                                <option value="billing">Billing</option>
                            </select>
                        )}
                    />
                </div>

                <div className='form-item w-100'>
                    <label>Message</label>
                    <Controller
                        name="message"
                        control={control}
                        rules={{
                            required: 'Message is required',
                            maxLength: {
                                value: 1000,
                                message: 'Message must be less than 1000 characters',
                            },
                        }}
                        render={({ field }) => (
                            <textarea {...field} rows="6" placeholder='Enter your message here...' />
                        )}
                    />
                    {errors.message && <p className="error">{errors.message.message}</p>}
                </div>
                <Turnstile userRef={turnstileRef} sitekey={process.env.REACT_APP_TURNSTILE_KEY} onVerify={setToken} />

                <button id="submit-form" type="submit" disabled={isLoading || !token}>
                    {isLoading && <div className="loader"></div>}
                    Send Message
                </button>
                {
                    error && <p className='error'>
                        {error}
                    </p>
                }
                {
                    message && <p className='form-result'>
                        {message}
                    </p>
                }
                <div className='urgent'>
                    <span>Do you require urgent assistance? Join our <a href='https://discord.gg/primedproxies'>Discord</a> and create a support ticket!</span>
                </div>
            </form>
        </div>
    );
}

export default ContactForm;