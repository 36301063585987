import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./FeedbackModal.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import userService from "../../../Services/user.service";

function FeedbackModal() {
    const { handleSubmit, control, reset, getValues, formState: { errors } } = useForm();

    const { user } = useSelector(state => state.auth);

    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [error, setError] = useState();

    const onSubmit = (data) => {
        setLoading(true);
        setError(null);
        setMessage(null);

        userService.submitFeedback(data)
            .then(() => {
                setMessage("Thank you for your feedback, we have successfully received your form!");

                reset({ fullName: "", email: "", subject: "", message: "" });
            })
            .catch((error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();


                setError(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <PopupModal>
            <div className="feedback-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Send Feedback</h3>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-item w-100">
                            <label>Full Name</label>
                            <Controller
                                name="fullName"
                                control={control}
                                rules={{
                                    required: 'First Name is required',
                                }}
                                defaultValue={user.firstName + ' ' + user.lastName}
                                render={({ field }) => (
                                    <input type="text" {...field} readOnly placeholder="Enter your full name..." />
                                )}
                            />
                            <p className="error">{errors.fullName?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <label>Email</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: 'Invalid email',
                                    },
                                }}
                                defaultValue={user.email}
                                render={({ field }) => (
                                    <input type="text" {...field} readOnly placeholder="Enter your email..." />
                                )}
                            />
                            <p className="error">{errors.email?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <label>Subject</label>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{
                                    required: 'Subject is required',
                                }}
                                render={({ field }) => (
                                    <input type="text" {...field} placeholder="Enter your subject..." />
                                )}
                            />
                            <p className="error">{errors.subject?.message}</p>
                        </div>
                        <div className='form-item w-100'>
                            <label>Message</label>
                            <Controller
                                name="message"
                                control={control}
                                rules={{
                                    required: 'Message is required',
                                }}
                                render={({ field }) => (
                                    <textarea {...field} rows="6" placeholder='Enter your feedback here...' />
                                )}
                            />
                            {errors.message && <p className="error">{errors.message.message}</p>}
                        </div>
                        {
                            error && <p className='error'>
                                {error}
                            </p>
                        }
                        <button id="submit-form" type="submit" disabled={isLoading}>
                            {isLoading && <div className="loader"></div>}
                            Submit
                        </button>
                        {
                            message && <p className='form-result'>
                                {message}
                            </p>
                        }
                    </form>
                </div>
            </div>
        </PopupModal>
    )
}

export default FeedbackModal;