import OrderDetails from "../../../Components/Dashboard/OrderDetails/OrderDetails";
import OrderSelector from "../../../Components/Dashboard/OrderSelector/OrderSelector";

import products from '../../../data/products';

import "./Servers.scss"
import ServerCard from "../../../Components/Landing/ServerCard/ServerCard";
import ServerResult from "../../../Components/Dashboard/ServerResult/ServerResult";
import MultiOrder from "../../../Components/Dashboard/MultiOrder/MultiOrder";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SERVER_ADVANCED, SERVER_BASIC } from "../../../data/product";
import userService from "../../../Services/user.service";
import { SET_SERVERDATA } from "../../../Actions/type";

function Servers() {
    let width = window.innerWidth;

    const dispatch = useDispatch();
    const { plans } = useSelector(state => state.user);

    const getOrders = () => plans?.filter(x => x.productId == SERVER_BASIC || x.productId == SERVER_ADVANCED)

    return (
        <>
            {
                !getOrders() || getOrders()?.length <= 0 ?
                    <>
                        <div className="empty-page-container">
                            <div className="empty-container">
                                <img src={require(`../../../assets/icons/locked.svg`).default} />
                                <div className="notice">
                                    <span>You don't have any <b>Server</b> plans</span>
                                    <span>You can purchase one <Link to="/dashboard">here</Link>.</span>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="dashboard-page server-page-container">
                            <div className="left-container">
                                <div className="data-container">
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Server List</span>
                                    </div>
                                    <OrderSelector title="Server Orders" subtitle="Generate via Order ID below to access your plan" orders={getOrders()}  onGenerate={(order) => userService.getServerData(order).then((data) => dispatch({
                                        type: SET_SERVERDATA,
                                        payload: data,
                                    }))}/>
                                    {
                                        width < 992 ? (
                                            <div className="result-container">
                                                <ServerResult />
                                            </div>
                                        ) : <></>
                                    }
                                    <div className="subtitle-container">
                                        <span className="dashboard-subtitle">Active Plans</span>
                                    </div>
                                    {getOrders() && getOrders().length > 1 ? <MultiOrder orders={getOrders()} server/> : <OrderDetails order={getOrders()[0]} server/>}
                                </div>
                                {
                                    width >= 992 ? (
                                        <div className="result-container">
                                            <ServerResult />
                                        </div>
                                    ) : <></>
                                }
                            </div>
                            <div className="purchase-container">
                                <div className="subtitle-container">
                                    <span className="dashboard-subtitle">Purchase</span>
                                </div>
                                <ServerCard value={products.servers} />
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Servers;