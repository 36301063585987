import adminService from "../Services/admin.service";
import { SET_ADMIN_ANALYTICS } from "./type";

export const getAdminAnalytics = (type) => (dispatch) => {
    return adminService.getAnalytics().then(
        (data) => {

            dispatch({
                type: SET_ADMIN_ANALYTICS,
                payload: data
            })

            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
}