import { useNavigate, useParams } from "react-router";
import "./BlogPost.scss";
import { useEffect } from "react";
import blogService from "../../../Services/blog.service";
import { useState } from "react";
import Blocks from 'editorjs-blocks-react-renderer';
import Footer from "../../../Components/Landing/Footer/Footer";
import { Helmet } from "react-helmet";

function BlogPost() {

    const navigate = useNavigate();

    let { path } = useParams();

    const width = window.innerWidth;
    const [data, setData] = useState();
    const [post, setPost] = useState();

    useEffect(() => {
        blogService.getPost(path).then((data) => {

            setPost(data);

            blogService.getBlogOverview().then((data) => {
                setData(data);
            });
        })
            .catch((error) => {
                if (error.response.status == 404)
                    navigate('/blog');
            });
    }, [path]);

    const handleClick = (post) => {
        navigate('/blog/' + post.path);
    };

    return (
        <>
            {
                post && <Helmet>
                    <title>{post.title}</title>
                    <meta name="description"
                        content={post.data.blocks.find(x => x.type == "paragraph")?.data.text.substring(0, 175)}></meta>
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:title" content={post.title}></meta>
                    <meta property="og:description"
                        content={post.data.blocks.find(x => x.type == "paragraph")?.data.text.substring(0, 175)}></meta>
                    <meta property="twitter:title" content={post.title}></meta>
                    <meta property="twitter:description"
                        content={post.data.blocks.find(x => x.type == "paragraph")?.data.text.substring(0, 175)}></meta>
                </Helmet>
            }
            <div className="blog-post-page">
                <div className="top-container">
                    <div className="left-container">
                        <div className="subtitle-container">
                            <span className="dashboard-subtitle">FEATURED ARTICLE</span>
                            <div className="post-container">
                                {post && <Blocks data={post.data} />}
                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className="subtitle-container">
                            <span className="dashboard-subtitle">RECENT ARTICLES</span>
                        </div>
                        <div className="recent-list-container">
                            {
                                data?.recent.length > 0 && data.recent.map((article, index) => (
                                    <>
                                        <article key={article.id} onClick={() => handleClick(article)}>
                                            <div className="image-container" style={{ backgroundImage: `url("${article.image}")`, backgroundSize: "cover" }}></div>
                                            <div className="article-details">
                                                <div className="flex-row">
                                                    <span className="date">{new Date(article.created).toDateString().substring(4)}</span>
                                                    <img className="clock-icon" src={require('./../../../assets/icons/clock.svg').default} />
                                                    <span className="read-time">{Math.max(Math.floor(article.data.toString().length / 300), 1)} min read</span>
                                                </div>
                                                <p className="title">{article.title.substring(0, 80)}{article.title.length > 80 ? "..." : ""}</p>
                                            </div>
                                        </article>
                                        {index != (data.recent.length - 1) && <hr></hr>}
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {
                    data?.featured.length > 1 &&
                    <div className="bottom-container">
                        <div className="subtitle-container">
                            <span className="dashboard-subtitle">BEST ARTICLES</span>
                        </div>
                        <div className="featured-list-container">
                            {
                                data && data?.featured.slice(1, 5).map((article, index) => (
                                    <>
                                        <article key={article.id} onClick={() => handleClick(article)}>
                                            <div className="image-container" style={{ backgroundImage: `url("${article.image}")`, backgroundSize: "cover" }}></div>
                                            {
                                                width < 1200 ?
                                                    <div className="article-details">
                                                        <div className="flex-row">
                                                            <span className="date">{new Date(article.created).toDateString().substring(4)}</span>
                                                            <img className="clock-icon" src={require('./../../../assets/icons/clock.svg').default} />
                                                            <span className="read-time">{Math.max(Math.floor(article.data.toString().length / 300), 1)} min read</span>
                                                        </div>
                                                        <p className="title">{article.title.substring(0, 80)}{article.title.length > 80 ? "..." : ""}</p>
                                                    </div>
                                                    :
                                                    <div className="article-details">
                                                        <p className="title">{article.title.substring(0, 80)}{article.title.length > 80 ? "..." : ""}</p>
                                                        <div className="flex-row">
                                                            <span className="date">{new Date(article.created).toDateString().substring(4)}</span>
                                                            <span className="dot"> • </span>
                                                            <img className="clock-icon" src={require('./../../../assets/icons/clock.svg').default} />
                                                            <span className="read-time">{Math.max(Math.floor(article.data.toString().length / 300), 1)} min read</span>
                                                        </div>
                                                    </div>
                                            }
                                        </article>
                                        {index != (data.featured.length - 2) && <hr></hr>}
                                    </>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </>
    )
}

export default BlogPost;