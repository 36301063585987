import { SET_DAILY_ANALYTICS, SET_ISPS, SET_MOBILE, SET_MONTHLY_ANALYTICS, SET_PLANS, SET_PROXIES, SET_RESIDATA, SET_SERVERDATA } from "../Actions/type";

const initialState = {}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PLANS:
      return {
        ...state,
        plans: payload
      };

    case SET_RESIDATA:
      return {
        ...state,
        resiData: payload
      }

    case SET_PROXIES:
      return {
        ...state,
        proxies: payload
      }

    case SET_ISPS:
      return {
        ...state,
        isps: payload
      }

    case SET_SERVERDATA:
      return {
        ...state,
        server: payload
      }

    case SET_DAILY_ANALYTICS:
      return {
        ...state,
        dailyUsage: payload
      }

    case SET_MONTHLY_ANALYTICS:
      return {
        ...state,
        monthlyUsage: payload
      }

    case SET_MOBILE:
      return {
        ...state,
        mobile: payload
      }

    default:
      return state;
  }
}