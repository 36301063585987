import { Line } from "react-chartjs-2";
import React from "react";

function DataUsageChart({ data, dataPlan, labels, timeUnit }) {

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Data Used',
                data: data,
                fill: true,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const chartArea = context.chart.chartArea;
                    let gradient;
                    if (chartArea)
                        gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    else
                        gradient = ctx.createLinearGradient(0, 0, 0, 150);
                    gradient.addColorStop(0, 'rgba(249,194,60,0.4)');
                    gradient.addColorStop(1, 'rgba(249,194,60,0)');
                    return gradient;
                },
                borderColor: '#FFA726',
                borderWidth: 1,
                tension: 0.1,
                pointRadius: 0
            },
            {
                label: 'Data Plan',
                data: dataPlan,
                fill: false,
                borderColor: 'transparent',
                pointRadius: 0,
                tension: 0.1,
            }
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                backgroundColor: "#FFFFFF",
                borderColor: "#F0F0F0",
                borderWidth: 1,
                titleColor: "black",
                titleFont: {
                    family: "Suisse-Medium",
                    size: 12
                },
                titleAlign: "center",
                bodyColor: "black",
                bodyAlign: 'center',
                caretSize: 0,
                displayColors: false,
                bodyFont: {
                    family: "Suisse-Regular",
                    size: 11
                },
                padding: 10,
                callbacks: {
                    beforeLabel: function (tooltipItem, data) {
                        return [tooltipItem.dataset.label]
                    },
                    label: function (tooltipItem, data) {
                        return [`${tooltipItem.formattedValue}GB`]
                    },
                }
            },
            crosshair: {
                line: {
                    color: "#F0F0F0",
                    width: 1,
                },
                sync: {
                    enabled: false
                },
                zoom: {
                    enabled: false
                },
            }
        },
        maintainAspectRatio: false,

        borderColor: 'transparent',
        scales: {
            x: {
                type: "time",
                time: {
                    unit: timeUnit
                },
                ticks: {
                    display: true,
                    font: {
                        size: 9
                    },
                },
                grid: {
                    drawBorder: false,
                    display: false,
                    lineWidth: 0
                },
            },
            y: {
                min: 0,
                ticks: {
                    display: false,
                    beginAtZero: true,
                },
                border: {
                    display: false
                }
            },
        },
    };

    return <Line data={chartData} options={options} />;
}

export default DataUsageChart;