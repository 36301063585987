import "./IspAdmin.scss";
import GenerateResult from "../../../Components/Dashboard/GenerateResult/GenerateResult";
import { useEffect, useState } from "react";
import adminService from "../../../Services/admin.service";

function IspAdmin() {

    const [proxies, setProxies] = useState([]);

    useEffect(() => {
        adminService.getAllIsps().then((result) => setProxies(result));
    }, []);

    return (
        <div className="dashboard-page isp-admin-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">ALL</span>
            </div>
            <div className="result-container">
                <GenerateResult proxies={proxies} />
            </div>

        </div>
    )
}

export default IspAdmin;