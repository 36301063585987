import axios from 'axios';
import { logout } from '../Actions/auth';
import store from '../store';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
});
 
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            store.dispatch(logout());
        }

        return Promise.reject(error);
    }
);

export default api;