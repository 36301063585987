import authHeader from './auth-header';
import api from './api';

class UserService {

    getPlans() {
        return api.get('/user/plans', { headers: authHeader() })
            .then((response) => response.data);
    }

    getResiData() {
        return api.get('/user/resi', { headers: authHeader() })
            .then((response) => response.data);
    }

    getResiAnalytics(type) {
        return api.get('/user/resi-analytics?type=' + (type ?? "daily"), { headers: authHeader() })
            .then((response) => response.data);
    }

    submitFeedback(data) {
        return api.post('/user/feedback', data, { headers: authHeader() })
            .then((response) => response.data);
    }

    getIsps(order) {
        return api.get('/user/isps/' + order, { headers: authHeader() })
            .then((response) => response.data);
    }

    getServerData(order) {
        return api.get('/user/server/' + order, { headers: authHeader() })
            .then((response) => response.data);
    }

    getMobile(data) {
        return api.get(`/user/mobile/${data.order}?type=${data.type}`, { headers: authHeader() })
            .then((response) => response.data);
    }

    submitMobileReset(order) {
        return api.post('/user/mobile/' + order + "/reset", undefined, { headers: authHeader() })
            .then((response) => response.data);
    }

    submitResiReset() {
        return api.post('/user/resi/reset', undefined, { headers: authHeader() })
            .then((response) => response.data);
    }
}

export default new UserService();