import { SET_ADMIN_ANALYTICS } from "../Actions/type";

const initialState = {}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ADMIN_ANALYTICS:
      return {
        ...state,
        analytics: payload
      };

    default:
      return state;
  }
}