import { useEffect, useState } from "react";

import "./ServerCard.scss";
import { useSelector } from "react-redux";
import Loading from "../../General/Loading/Loading";
import { useModal } from "../../../Contexts/ModalContext";
import LoginModal from "../../Auth/LoginModal/LoginModal";
import purchaseService from "../../../Services/purchase.service";
import publicService from "../../../Services/public.service";

function ServerCard(props) {
    const [currentServer, setCurrentServer] = useState(props.value[0]);

    const { prices } = useSelector(state => state.publics);
    const { isLoggedIn } = useSelector(state => state.auth);

    const { openModal } = useModal();

    const [isLoading, setLoading] = useState(false);

    const [isFetchingStock, setFetchingStock] = useState(false);

    const [stock, setStock] = useState(-1);

    function onToggle() {
        props.onToggle(currentServer);
    }

    const handlePurchase = () => {
        setLoading(true);

        const request = { productId: currentServer.id, quantity: 1, price: product().pricePerUnit };

        if (!isLoggedIn) {
            openModal(LoginModal, request);
            setLoading(false);
        } else {
            purchaseService.purchase(request).then((data) => {
                window.location.href = data.url;
            });
        }
    }

    const product = () => prices?.find(x => x.id == currentServer.id);

    useEffect(() => {
        setFetchingStock(true);
        publicService.getStock(currentServer.id).then((data) => {
            setStock(data.availability);
            setFetchingStock(false);
        }).catch(() => {
            setStock(0);
            setFetchingStock(false);
        });
    }, [currentServer]);

    return (
        <div className="server-card">
            <div className="server-card-header">
                <div className="server-card-title-container">
                    <div className="icon-container">
                        <img src={require(`../../../assets/icons/server.svg`).default} />
                    </div>
                    <span className="server-card-title">{currentServer.name}</span>
                    <div className="product-selector">
                        {
                            props.value.map((server) => (
                                <button key={server.type} className={currentServer.type === server.type ? "selected" : ""} onClick={() => setCurrentServer(server)}>{server.type}</button>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="server-card-body">
                {
                    props.extended ?
                        <span className="server-card-description">{currentServer.description}</span>
                        : <></>
                }
                <div className="price-container">
                    {
                        !product() ?
                            <Loading height="35px" width="100px" />
                            :
                            <>
                                <span className="price-total">£{product().pricePerUnit}</span>
                                <span className="price-detail">/ {product().priceDescription}</span>
                            </>
                    }
                </div>
                <div className="specs-container">
                    {
                        currentServer.specs.map((spec) => (
                            <div className="chip">
                                <span>{spec}</span>
                            </div>
                        ))
                    }
                </div>
                <button id="buy-now" className={`${stock < 0 || stock < 1 ? "oos" : ""}`} onClick={handlePurchase} disabled={isLoading || stock < 1}>
                    {(isLoading || isFetchingStock) && <div className="loader"></div>}
                    {stock < 0 ? "Fetching availability" : (stock < 1 ? "Out of stock" : props.buyText ?? (props.showDetails ? "Purchase" : "Buy Now"))}
                </button>
                {
                    props.extended && props.onToggle ?
                        <button className="show-details" onClick={onToggle}>{props.showDetails ? "Hide Product Details" : "Show Product Details"}</button>
                        : <></>
                }
            </div>
            <div className="server-card-footer">
                <span className="details-title">Features</span>
                <div className="details-container">
                    {
                        currentServer.details.map((point) => (
                            <div className="details-row">
                                <img src={require('../../../assets/icons/checkmark-2.svg').default} />
                                <span>{point}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ServerCard;