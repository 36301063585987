import { useDispatch, useSelector } from "react-redux";
import Card from "../../General/Card/Card";
import DataUsageChart from "../DataUsageChart/DataUsageChart";

import "./ResiCard.scss"
import { PROXY_RESI } from "../../../data/product";
import Loading from "../../General/Loading/Loading";
import Residential from "../../../Pages/Dashboard/Residential/Residential";
import { getResiAnalytics } from "../../../Actions/user";
import { useEffect, useState } from "react";

import 'chartjs-adapter-moment';

function ResiCard(props) {

    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const { plans, resiData, dailyUsage, monthlyUsage } = useSelector(state => state.user);

    const getOrder = () => plans?.find(x => x.productId == PROXY_RESI);

    const width = window.innerWidth;
    const [graphType, setGraphType] = useState("hourly")

    useEffect(() => {
        if (!dailyUsage) {
            dispatch(getResiAnalytics());
        }
    }, [])

    const handleGraphChange = (type) => {
        setGraphType(type);

        if (type == "daily" && !monthlyUsage) {
            setLoading(true);
            dispatch(getResiAnalytics("monthly")).then(() => {
                setLoading(false);
            });
        }
    };

    const getDataSet = () => (graphType == "hourly" ? dailyUsage : monthlyUsage);

    return (
        <Card classes="resi-card">
            <div className="card-header">
                <div className="card-icon">
                    <img src={require(`../../../assets/icons/resi-filled.svg`).default} />
                </div>
                <span className="card-title">Residential</span>
                {props.extended && width > 992 && <div className="product-selector">
                    <button className={graphType == "hourly" ? "selected" : ""} onClick={() => handleGraphChange("hourly")}>Hourly</button>
                    <button className={graphType == "daily" ? "selected" : ""} onClick={() => handleGraphChange("daily")}>Daily</button>
                </div>}
                {
                    getOrder() && <div className="expiry-container">
                        {resiData ? <span>{resiData.trafficRemaining.toFixed(2)} / {resiData.trafficLimit.toFixed(2)}GB Left</span> : <Loading width="70px" height="15px"></Loading>}
                    </div>
                }
            </div>
            <div className="card-body">
                {props.extended && width <= 992 && <div className="product-selector">
                    <button className={graphType == "hourly" ? "selected" : ""} onClick={() => handleGraphChange("hourly")}>Hourly</button>
                    <button className={graphType == "daily" ? "selected" : ""} onClick={() => handleGraphChange("daily")}>Daily</button>
                </div>}
                {
                    !getOrder() ?
                        <>
                            <img src={require(`../../../assets/icons/locked.svg`).default} />
                            <div className="notice">
                                <span>You don't have any <b>Residential</b> plans</span>
                                <span>You can purchase one below.</span>
                            </div>
                        </>
                        :
                        <>
                            {getDataSet() && getDataSet()?.length < 2 && <p>No data available</p>}
                            <div className={`chart-container ${!getDataSet() || getDataSet()?.length < 2 || isLoading ? "hidden" : ""}`}>
                                <DataUsageChart
                                    data={getDataSet()?.map(x => x.traffic)}
                                    dataPlan={getDataSet()?.map(x => x.trafficLimit)}
                                    labels={getDataSet()?.map(x => Date.parse(x.timeStamp))}
                                    timeUnit={graphType == "hourly" ? "hour" : "day"}
                                />
                            </div>
                            {!dailyUsage || isLoading && <div className="loader" style={{ width: "25px", height: "25px" }} />}
                        </>
                }
            </div>
        </Card>
    )
}

export default ResiCard;