import publicService from "../Services/public.service";
import { SET_PRICES } from "./type";

export const getPrices = () => (dispatch) => {
    return publicService.getPrices().then(
        (data) => {
            dispatch({
                type: SET_PRICES,
                payload: data,
            });

            return Promise.resolve();
        },
        () => {
            return Promise.reject();
        }
    );
};