import { useEffect, useState } from "react";
import "./Analytics.scss";
import CountUp from 'react-countup';
import publicService from "../../../Services/public.service";
import Loading from "../../General/Loading/Loading";

function Analytics() {
    const [analytics, setAnalytics] = useState();

    useEffect(() => {
        publicService.getAnalytics().then((response) => {
            setAnalytics(response.data);
        });

        document.getElementsByClassName("analytics-scroll-container")[0].scrollLeft = 125;
    }, []);

    return (
        <div className="analytics-container">
            <div className="analytics-scroll-container">
                <div className="analytics-item-container">
                    <img alt="customers" src={require(`../../../assets/icons/analytics-1.svg`).default} />
                    <div className="analytics-data-container">
                        {analytics ? <CountUp className="counter" end={analytics?.customers} suffix="+" enableScrollSpy={true} scrollSpyOnce={true} /> : <Loading height="40px"/>}
                        <span className="description">Customers trust Primed</span>
                    </div>
                </div>
                <div className="analytics-item-container">
                    <img alt="proxies" src={require(`../../../assets/icons/analytics-2.svg`).default} />
                    <div className="analytics-data-container">
                        {analytics ? <CountUp className="counter" end={analytics?.proxies} enableScrollSpy={true} scrollSpyOnce={true} /> : <Loading height="40px"/>}
                        <span className="description">Active proxy IPs</span>
                    </div>
                </div>
                <div className="analytics-item-container">
                    <img alt="usage" src={require(`../../../assets/icons/analytics-3.svg`).default} />
                    <div className="analytics-data-container">
                        {analytics ? <CountUp className="counter" end={analytics?.usage} suffix=" GB" enableScrollSpy={true} scrollSpyOnce={true} /> : <Loading height="40px"/>}
                        <span className="description">Current proxy network usage</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics;