import { useEffect } from "react";
import DataCard from "../../../Components/General/DataCard/DataCard";
import "./Overview.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAnalytics } from "../../../Actions/admin";
import Card from "../../../Components/General/Card/Card";

function Overview() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminAnalytics());
    }, [])

    const { analytics } = useSelector(state => state.admin);

    return (
        <div className="dashboard-page overview-page-container">
            <div className="subtitle-container">
                <span className="dashboard-subtitle">Analytics</span>
            </div>
            <div className="analytics-overview-container">
                {
                    analytics && <>
                        <DataCard title="Members" data={analytics.users} />
                        <DataCard title="Fulfilled Orders" data={analytics.fulfilled} />
                        <DataCard title="Unfulfilled Orders" data={analytics.unfulfilled} />
                    </>
                }
            </div>
            <div className="subtitle-container mt-20">
                <span className="dashboard-subtitle">Swap Analytics</span>
            </div>
            <Card classes="swap-card mt-20">
                <table>
                    <thead>
                        <tr>
                            <th>Provider</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                            <th>Users</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            analytics && Array.apply(0, Array(analytics.swap.length)).map(function (x, i) {
                                return <tr key={i}>
                                    <td>{analytics.swap[i].provider}</td>
                                    <td>{analytics.swap[i].totalOld.toFixed(2)} GB</td>
                                    <td>{analytics.swap[i].totalNew.toFixed(2)} GB</td>
                                    <td>{analytics.swap[i].amount}</td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>
            </Card>
        </div>
    )
}

export default Overview;