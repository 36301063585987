import { useEffect } from "react";
import Footer from "../../Components/Landing/Footer/Footer";
import "./Blog.scss";
import { useState } from "react";
import blogService from "../../Services/blog.service";
import { useNavigate } from "react-router";

function Blog() {

    const width = window.innerWidth;

    const navigate = useNavigate();
    const [data, setData] = useState();

    useEffect(() => {
        blogService.getBlogOverview().then((data) => {
            setData(data);
        });
    }, []);

    const handleClick = (post) => {
        navigate('/blog/' + post.path);
    };

    return (
        <>
            <div className="blog-page">
                <div className="top-container">
                    <div className="left-container">
                        <div className="subtitle-container">
                            <span className="dashboard-subtitle">FEATURED ARTICLES</span>
                        </div>
                        {
                            data?.featured.length > 0 && <>
                                <div className="featured-container" style={{ backgroundImage: `url("${data.featured[0].image}")`, backgroundSize: "cover" }} onClick={() => handleClick(data.featured[0])}>
                                    {
                                        width >= 1200 &&
                                        <>
                                            <span className="title">{data.featured[0].title}</span>
                                            <span className="sub-title">{data.featured[0].data.blocks.find(x => x.type == "paragraph")?.data.text.substring(0, 165)}...</span>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        {
                            (data?.featured.length > 0 && width < 1200) &&
                            <div className="title-container">
                                <span className="title">{data.featured[0].title}</span>
                                <span className="sub-title">{data.featured[0].data.blocks.find(x => x.type == "paragraph")?.data.text.substring(0, 165)}...</span>
                            </div>
                        }
                    </div>
                    <div className="right-container">
                        <div className="subtitle-container">
                            <span className="dashboard-subtitle">RECENT ARTICLES</span>
                        </div>
                        <div className="recent-list-container">
                            {
                                data?.recent.length > 0 && data.recent.map((article, index) => (
                                    <>
                                        <article key={article.id} onClick={() => handleClick(article)}>
                                            <div className="image-container" style={{ backgroundImage: `url("${article.image}")`, backgroundSize: "cover" }}></div>
                                            <div className="article-details">
                                                <div className="flex-row">
                                                    <span className="date">{new Date(article.created).toDateString().substring(4)}</span>
                                                    <img className="clock-icon" src={require('./../../assets/icons/clock.svg').default} />
                                                    <span className="read-time">{Math.max(Math.floor(article.data.toString().length / 300), 1)} min read</span>
                                                </div>
                                                <p className="title">{article.title.substring(0, 80)}{article.title.length > 80 ? "..." : ""}</p>
                                            </div>
                                        </article>
                                        {index != (data.recent.length - 1) && <hr></hr>}
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {
                    data?.featured.length > 1 &&
                    <div className="bottom-container">
                        <div className="subtitle-container">
                            <span className="dashboard-subtitle">BEST ARTICLES</span>
                        </div>
                        <div className="featured-list-container">
                            {
                                data && data?.featured.slice(1, 5).map((article, index) => (
                                    <>
                                        <article key={article.id} onClick={() => handleClick(article)}>
                                            <div className="image-container" style={{ backgroundImage: `url("${article.image}")`, backgroundSize: "cover" }}></div>
                                            {
                                                width < 1200 ?
                                                    <div className="article-details">
                                                        <div className="flex-row">
                                                            <span className="date">{new Date(article.created).toDateString().substring(4)}</span>
                                                            <img className="clock-icon" src={require('./../../assets/icons/clock.svg').default} />
                                                            <span className="read-time">{Math.max(Math.floor(article.data.toString().length / 300), 1)} min read</span>
                                                        </div>
                                                        <p className="title">{article.title.substring(0, 80)}{article.title.length > 80 ? "..." : ""}</p>
                                                    </div>
                                                    :
                                                    <div className="article-details">
                                                        <p className="title">{article.title.substring(0, 80)}{article.title.length > 80 ? "..." : ""}</p>
                                                        <div className="flex-row">
                                                            <span className="date">{new Date(article.created).toDateString().substring(4)}</span>
                                                            <span className="dot"> • </span>
                                                            <img className="clock-icon" src={require('./../../assets/icons/clock.svg').default} />
                                                            <span className="read-time">{Math.max(Math.floor(article.data.toString().length / 300), 1)} min read</span>
                                                        </div>
                                                    </div>
                                            }
                                        </article>
                                        {index != (data.featured.length - 2) && <hr></hr>}
                                    </>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </>
    )
}

export default Blog;