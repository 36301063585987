import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./RegisterModal.scss";
import { useModal } from "../../../Contexts/ModalContext";
import LoginModal from "../LoginModal/LoginModal";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../../Actions/auth";
import TwoFactorModal from "../TwoFactorModal/TwoFactorModal";

function RegisterModal() {
    const turnstile = useTurnstile();
    const turnstileRef = useRef(null);
    const [token, setToken] = useState();

    const { handleSubmit, control, getValues, formState: { errors } } = useForm();

    const { openModal, closeModal } = useModal();

    const [isLoading, setLoading] = useState(false);
    const { message } = useSelector(state => state.message);

    const dispatch = useDispatch();

    const handleOpenLogin = () => {
        closeModal();
        openModal(LoginModal);
    }

    const onSubmit = (data) => {
        setLoading(true);

        data.token = token;

        dispatch(register(data))
            .then(() => {
                closeModal();
                openModal(TwoFactorModal, data);
            })
            .catch(() => {
                turnstile.reset(turnstileRef.current);
                setLoading(false);
            });
    };

    return (
        <PopupModal>
            <div className="register-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Create an account</h3>
                    <p className="modal-subtitle">Please enter your personal details to continue.</p>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-item w-50">
                            <label>First Name</label>
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{
                                    required: 'First Name is required',
                                }}
                                render={({ field }) => (
                                    <input type="text" {...field} placeholder="Enter your first name..." />
                                )}
                            />
                            <p className="error">{errors.firstName?.message}</p>
                        </div>
                        <div className="form-item w-50">
                            <label>Last Name</label>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{
                                    required: 'Last Name is required',
                                }}
                                render={({ field }) => (
                                    <input type="text" {...field} placeholder="Enter your last name..." />
                                )}
                            />
                            <p className="error">{errors.lastName?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <label>Email</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: 'Invalid email',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="text" {...field} placeholder="Enter your email..." />
                                )}
                            />
                            <p className="error">{errors.email?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <label>Password</label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 6,
                                        message: 'Password must be at least 6 characters',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="password" {...field} placeholder="Enter your password..." />
                                )}
                            />
                            <p className="error">{errors.password?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <label>Confirm Password</label>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                rules={{
                                    required: 'Confirm Password is required',
                                    validate: (value) =>
                                        value === getValues('password') ||
                                        'Passwords do not match',
                                }}
                                render={({ field }) => (
                                    <input type="password" {...field} placeholder="Enter your password confirmation..." />
                                )}
                            />
                            <p className="error">{errors.confirmPassword?.message}</p>
                        </div>
                        <Turnstile userRef={turnstileRef} sitekey={process.env.REACT_APP_TURNSTILE_KEY} onVerify={setToken} />
                        {
                            message && <p className="error">
                                {message}
                            </p>
                        }
                        <button id="submit-form" type="submit" disabled={isLoading || !token}>
                            {isLoading && <div className="loader"></div>}
                            Create account
                        </button>
                    </form>
                </div>
                <div className="modal-footer">
                    <hr />
                    <p>Already have an account? Click <a className="text-underline" onClick={handleOpenLogin}>here</a>.</p>
                </div>
            </div>
        </PopupModal>
    )
}

export default RegisterModal;