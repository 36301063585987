import purchaseService from "../../../Services/purchase.service";
import utils from "../../../Utils/utils";
import { SERVER_ADVANCED } from "../../../data/product";
import Card from "../../General/Card/Card"
import "./OrderDetails.scss"

function OrderDetails({ order, server }) {

    const handleCancel = () => {
        purchaseService.getSession().then((data) => {
            window.location.href = data.url;
        });
    }

    return (
        <Card classes="order-details-card">
            <div className="card-header">
                <span className="card-title">{order.id}</span>
                <div className="expiry-container">
                    <span>{utils.getDaysLeft(order?.expiry)} DAYS LEFT</span>
                </div>
            </div>
            <div className="card-body">
                <div className="form">
                    <div className="order-details-container">
                        <div className="detail-item">
                            <span className="title">{server ? "Type" : "Quantity"}</span>
                            <span className="value">{server ? order?.productId == SERVER_ADVANCED ? "Advanced" : "Basic" : `${order?.properties["quantity"]} Proxies`}</span>
                        </div>
                        <div className="detail-item">
                            <span className="title">Expiry</span>
                            <span className="value">{new Date(order?.expiry).toLocaleString()}</span>
                        </div>
                    </div>
                    <button id="submit-form" type="submit" onClick={handleCancel}>Manage Subscription</button>
                </div>
            </div>
        </Card>
    )
}

export default OrderDetails;