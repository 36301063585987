import Card from "../../../Components/General/Card/Card";
import "./BlogManagement.scss";
import { createReactEditorJS } from 'react-editor-js'

import Paragraph from '@editorjs/paragraph'
import Image from '@editorjs/image'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Code from '@editorjs/code'
import Table from '@editorjs/table'
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import adminService from "../../../Services/admin.service";
import { useNavigate } from "react-router-dom";

const ReactEditorJS = createReactEditorJS();

function BlogManagement() {
    const editorInstanceRef = useRef(null);
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [isPublished, setPublished] = useState(false);
    const [isFeatured, setFeatured] = useState(false);

    const [posts, setPosts] = useState([]);
    const [isEditMode, setEditMode] = useState(false);
    const [path, setPath] = useState('');
    const [id, setId] = useState();

    useEffect(() => {
        adminService.getPosts().then((data) => setPosts(data));
    }, []);

    var blocks = {
        time: 1635603431943,
        blocks: [
            {
                id: "sheNwCUP5A",
                type: "header",
                data: {
                    text: "Article title",
                    level: 2
                }
            }]
    };

    const handleInitialize = useCallback((instance) => {
        editorInstanceRef.current = instance
    }, [])

    const handlePreview = async (event) => {
        setLoading(true);
        var post = await handleSave();

        adminService.createBlogPost(path, post).then(() => {
            window.location.reload(false);
        })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });

        event.preventDefault();
    };

    const handleBlogSave = async (event) => {
        setLoading(true);
        var post = await handleSave();

        var blog = {
            id: id,
            path: path,
            data: post,
            featured: isFeatured,
            published: isPublished
        }

        adminService.updateBlogPost(blog).then(() => {
            window.location.reload(false);
        })
            .catch(() => { })
            .finally(() => {
                setLoading(false);
            });

        event.preventDefault();
    };

    const handleReset = async () => {
        window.location.reload(false);
    };

    const handleSave = async () => {
        if (editorInstanceRef.current) {
            try {
                const savedData = await editorInstanceRef.current.save();
                return savedData;
            } catch (e) { }
        }
    };

    const handleSelect = (post) => {
        setId(post.id);
        editorInstanceRef.current.render(post.data);
        setPublished(post.published);
        setFeatured(post.featured);
        setEditMode(true);
        setPath(post.path);
    }

    const togglePublic = () => {
        setPublished(!isPublished);
    }

    const toggleFeatured = () => {
        setFeatured(!isFeatured);
    }

    const handleDelete = () => {
        adminService.deleteBlogPost(id).then(() => {
            window.location.reload(false);
        });
    }

    const handleView = () => {
        navigate("/blog/" + path);
    }

    return (
        <div className="dashboard-page blog-admin-page-container">
            <div className="left-container">
                <div className="subtitle-container">
                    <span className="dashboard-subtitle">Add Post</span>
                </div>
                <div className="add-container">
                    <form>
                        <div className="form-item w-100">
                            <Card classes="add-card">
                                <ReactEditorJS onInitialize={handleInitialize} tools={{ image: { class: Image, config: { endpoints: { byFile: 'https://api.primedproxies.com/api/v1/admin/image' } } }, header: Header, table: Table, quote: Quote, code: Code }} defaultValue={blocks} className="editor" />
                            </Card>
                        </div>
                    </form>
                </div>
                <div className="save-container">
                    <Card>
                        <button onClick={handleReset}>Reset</button>
                        <input value={path} onChange={(event) => setPath(event.target.value)} placeholder="article-about-sneakers" />
                        {isEditMode && <>
                            <button className={isPublished ? "selected" : ""} onClick={togglePublic}>Public</button>
                            <button className={isFeatured ? "selected" : ""} onClick={toggleFeatured}>Featured</button>
                        </>
                        }
                        <div className="align-right">
                            {
                                !isEditMode ?
                                    <button onClick={handlePreview} disabled={isLoading}>
                                        {isLoading && <div className="loader"></div>}
                                        Create Preview
                                    </button>
                                    : <>
                                        <button onClick={handleDelete} className="delete">Delete</button>
                                        <button onClick={handleView}>
                                            View
                                        </button>
                                        <button onClick={handleBlogSave} disabled={isLoading}>
                                            {isLoading && <div className="loader"></div>}
                                            Save
                                        </button>
                                    </>
                            }
                        </div>
                    </Card>
                </div>
            </div>
            <div className="right-container">
                <div className="subtitle-container">
                    <span className="dashboard-subtitle">Posts</span>
                </div>
                <div className="posts-container">
                    {
                        posts && posts.map(post => (
                            <Card key={post.id} classes={"post-card"}>
                                <div className="top-row" onClick={() => handleSelect(post)}>
                                    <div className="flex-column">
                                        <span className="creator">{post.creator}</span>
                                        <span>{new Date(post.created).toDateString()}</span>
                                        <span>Published: {post.published.toString()}</span>
                                        <span>Featured: {post.featured.toString()}</span>
                                    </div>
                                    {post.image && <img src={post.image} />}
                                </div>
                                <span className="title">{post.title}</span>
                            </Card>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default BlogManagement;