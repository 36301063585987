var genning = [
    {
        id: 1,
        group: "Ticketing",
        children: [
            {
                id: 100,
                name: "Events GB",
                domain: "eventsgb.primedproxies.com",
                countryCodes: [
                    "GB"
                ]
            },
            {
                id: 101,
                name: "Events EU",
                domain: "eventseu.primedproxies.com",
                countryCodes: [
                    "FR",
                    "IT",
                    "NL",
                    "AT",
                    "BE",
                    "CH",
                    "DE",
                    "DK",
                    "ES",
                    "SE"
                ]
            },
            {
                id: 102,
                name: "Events US",
                domain: "eventsus.primedproxies.com",
                countryCodes: [
                    "US",
                ]
            },
            {
                id: 103,
                name: "Events Asia",
                domain: "eventsasia.primedproxies.com",
                countryCodes: [
                    "CN",
                    "ID",
                    "SG",
                    "HK",
                    "JP",
                ]
            }
        ]
    },
    {
        id: 2,
        group: "Custom",
        children: [
            {
                id: 200,
                name: "Random EU",
                domain: "proxy.primedproxies.com",
                countryCodes: [
                    "AT",
                    "BE",
                    "BG",
                    "HR",
                    "CY",
                    "CZ",
                    "DK",
                    "EE",
                    "FI",
                    "FR",
                    "DE",
                    "GR",
                    "HU",
                    "IE",
                    "IT",
                    "LV",
                    "LT",
                    "LU",
                    "MT",
                    "NL",
                    "PL",
                    "PT",
                    "RO",
                    "SK",
                    "SI",
                    "ES",
                    "SE",
                ]
            },
            {
                id: 201,
                name: "Random Worldwide",
                domain: "proxy.primedproxies.com",
                countryCodes: [
                    'af',
                    'al',
                    'dz',
                    'ar',
                    'au',
                    'at',
                    'bs',
                    'bh',
                    'bd',
                    'by',
                    'be',
                    'bo',
                    'br',
                    'bg',
                    'ca',
                    'cl',
                    'co',
                    'cd',
                    'cr',
                    'hr',
                    'cy',
                    'cz',
                    'dk',
                    'ec',
                    'sv',
                    'ee',
                    'fj',
                    'fi',
                    'fr',
                    'pf',
                    'ga',
                    'gm',
                    'ge',
                    'de',
                    'gh',
                    'gi',
                    'gr',
                    'gl',
                    'gd',
                    'gu',
                    'gt',
                    'gn',
                    'gw',
                    'gy',
                    'ht',
                    'hn',
                    'hk',
                    'hu',
                    'is',
                    'in',
                    'id',
                    'ir',
                    'iq',
                    'ie',
                    'im',
                    'il',
                    'it',
                    'ci',
                    'jm',
                    'jp',
                    'je',
                    'jo',
                    'kz',
                    'ke',
                    'xk',
                    'kw',
                    'kg',
                    'lv',
                    'lb',
                    'ls',
                    'lr',
                    'ly',
                    'li',
                    'lt',
                    'lu',
                    'mo',
                    'mk',
                    'mg',
                    'mw',
                    'my',
                    'mv',
                    'kl',
                    'mt',
                    'mh',
                    'mr',
                    'mu',
                    'mx',
                    'md',
                    'mc',
                    'mn',
                    'me',
                    'ma',
                    'mz',
                    'mm',
                    'na',
                    'nr',
                    'np',
                    'nl',
                    'nz',
                    'nc',
                    'ni',
                    'ng',
                    'mp',
                    'no',
                    'om',
                    'pk',
                    'pw',
                    'ps',
                    'pa',
                    'pg',
                    'py',
                    'pe',
                    'ph',
                    'pl',
                    'pt',
                    'pr',
                    'qa',
                    're',
                    'ro',
                    'ru',
                    'rw',
                    'kn',
                    'lc',
                    'vc',
                    'sm',
                    'st',
                    'sa',
                    'sn',
                    'rs',
                    'sl',
                    'sg',
                    'sk',
                    'sl',
                    'so',
                    'za',
                    'kr',
                    'es',
                    'lk',
                    'pm',
                    'sd',
                    'sr',
                    'se',
                    'ch',
                    'sy',
                    'tw',
                    'th',
                    'tg',
                    'tt',
                    'tn',
                    'tr',
                    'tc',
                    'ug',
                    'ua',
                    'ae',
                    'uk',
                    'us',
                    'uy',
                    'uz',
                    've',
                    'vn',
                    'vg',
                    'ye',
                    'zm',
                    'zw'
                ]
            }
        ]
    },
    {
        id: 3,
        group: "Country",
        children: [
            {
                "id": 300,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "af"
                ],
                "name": "Afghanistan"
            },
            {
                "id": 301,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "al"
                ],
                "name": "Albania"
            },
            {
                "id": 302,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "dz"
                ],
                "name": "Algeria"
            },
            {
                "id": 303,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ar"
                ],
                "name": "Argentina"
            },
            {
                "id": 304,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "au"
                ],
                "name": "Australia"
            },
            {
                "id": 305,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "at"
                ],
                "name": "Austria"
            },
            {
                "id": 306,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bs"
                ],
                "name": "Bahamas"
            },
            {
                "id": 307,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bh"
                ],
                "name": "Bahrain"
            },
            {
                "id": 308,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bd"
                ],
                "name": "Bangladesh"
            },
            {
                "id": 309,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "by"
                ],
                "name": "Belarus"
            },
            {
                "id": 310,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "be"
                ],
                "name": "Belgium"
            },
            {
                "id": 311,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bo"
                ],
                "name": "Bolivia"
            },
            {
                "id": 312,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "br"
                ],
                "name": "Brazil"
            },
            {
                "id": 313,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "bg"
                ],
                "name": "Bulgaria"
            },
            {
                "id": 314,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ca"
                ],
                "name": "Canada"
            },
            {
                "id": 315,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cl"
                ],
                "name": "Chile"
            },
            {
                "id": 316,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "co"
                ],
                "name": "Colombia"
            },
            {
                "id": 317,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cd"
                ],
                "name": "Democratic Republic of the Congo"
            },
            {
                "id": 318,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cr"
                ],
                "name": "Costa Rica"
            },
            {
                "id": 319,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hr"
                ],
                "name": "Croatia"
            },
            {
                "id": 320,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cy"
                ],
                "name": "Cyprus"
            },
            {
                "id": 321,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "cz"
                ],
                "name": "Czech Republic"
            },
            {
                "id": 322,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "dk"
                ],
                "name": "Denmark"
            },
            {
                "id": 323,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ec"
                ],
                "name": "Ecuador"
            },
            {
                "id": 324,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sv"
                ],
                "name": "El Salvador"
            },
            {
                "id": 325,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ee"
                ],
                "name": "Estonia"
            },
            {
                "id": 326,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "fj"
                ],
                "name": "Fiji"
            },
            {
                "id": 327,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "fi"
                ],
                "name": "Finland"
            },
            {
                "id": 328,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "fr"
                ],
                "name": "France"
            },
            {
                "id": 329,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pf"
                ],
                "name": "French Polynesia"
            },
            {
                "id": 330,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ga"
                ],
                "name": "Gabon"
            },
            {
                "id": 331,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gm"
                ],
                "name": "Gambia"
            },
            {
                "id": 332,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ge"
                ],
                "name": "Georgia"
            },
            {
                "id": 333,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "de"
                ],
                "name": "Germany"
            },
            {
                "id": 334,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gh"
                ],
                "name": "Ghana"
            },
            {
                "id": 335,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gi"
                ],
                "name": "Gibraltar"
            },
            {
                "id": 336,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gr"
                ],
                "name": "Greece"
            },
            {
                "id": 337,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gl"
                ],
                "name": "Greenland"
            },
            {
                "id": 338,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gd"
                ],
                "name": "Grenada"
            },
            {
                "id": 339,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 340,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 341,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 342,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 343,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "gy"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 344,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ht"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 345,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 346,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 347,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "hu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 348,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "is"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 349,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "in"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 350,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "id"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 351,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ir"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 352,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "iq"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 353,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ie"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 354,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "im"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 355,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "il"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 356,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "it"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 357,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ci"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 358,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "jm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 359,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "jp"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 360,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "je"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 361,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "jo"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 362,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 363,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ke"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 364,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "xk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 365,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 366,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 367,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lv"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 368,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lb"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 369,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ls"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 370,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 371,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ly"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 372,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "li"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 373,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 374,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 375,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mo"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 376,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 377,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 378,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 379,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "my"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 380,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mv"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 381,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 382,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 383,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mh"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 384,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 385,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mu"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 386,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mx"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 387,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "md"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 388,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 389,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 390,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "me"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 391,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ma"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 392,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 393,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 394,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "na"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 395,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 396,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "np"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 397,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 398,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 399,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "nc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 400,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ni"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 401,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ng"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 402,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "mp"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 403,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "no"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 404,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "om"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 405,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 406,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 407,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ps"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 408,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pa"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 409,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 410,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "py"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 411,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pe"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 412,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ph"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 413,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 414,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 415,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 416,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "qa"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 417,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "re"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 418,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ro"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 419,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ru"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 420,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "rw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 421,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 422,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 423,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "vc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 424,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 425,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "st"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 426,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sa"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 427,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 428,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "rs"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 429,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 430,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 431,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 432,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sl"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 433,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "so"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 434,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "za"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 435,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "kr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 436,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "es"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 437,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "lk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 438,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "pm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 439,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sd"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 440,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 441,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "se"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 442,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ch"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 443,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "sy"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 444,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tw"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 445,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "th"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 446,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 447,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tt"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 448,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 449,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tr"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 450,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "tc"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 451,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ug"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 452,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ua"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 453,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ae"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 454,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "uk"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 455,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "us"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 456,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "uy"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 457,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "uz"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 458,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ve"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 459,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "vn"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 460,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "vg"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 461,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "ye"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 462,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "zm"
                ],
                "name": "Unknown Country"
            },
            {
                "id": 463,
                "domain": "proxy.primedproxies.com",
                "countryCodes": [
                    "zw"
                ],
                "name": "Unknown Country"
            }
        ]
    },
]

export default genning;