import React, { Children } from 'react';
import Modal from 'react-modal';

import "./PopupModal.scss";
import { useModal } from '../../../Contexts/ModalContext';

Modal.setAppElement('#root');

const PopupModal = ({children}) => {
    const { closeModal } = useModal();

    const closeModalLocal = () => {
        closeModal();
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModalLocal}
            contentLabel="Login Modal"
            className="popup-modal"
            overlayClassName="popup-modal-overlay"
        >
            {children}
        </Modal>
    );
};

export default PopupModal;
