import { Link } from "react-router-dom";
import Card from "../../General/Card/Card";

import "./ServersCard.scss"
import { useSelector } from "react-redux";
import { SERVER_ADVANCED, SERVER_BASIC } from "../../../data/product";

function ServersCard() {

    const { plans } = useSelector(state => state.user);

    const getOrders = () => plans?.filter(x => x.productId == SERVER_BASIC || x.productId == SERVER_ADVANCED)

    return (
        <Card classes="servers-card">
            <div className="card-header">
                <div className="card-icon">
                    <img src={require(`../../../assets/icons/servers-filled.svg`).default} />
                </div>
                <span className="card-title">Servers</span>
                {
                    getOrders()?.length > 0 && <div className="expiry-container">
                        <span>Multiple Plans</span>
                    </div>
                }
            </div>
            <div className="card-body">
                {
                    !getOrders() || getOrders()?.length <= 0 ?
                        <>
                            <img src={require(`../../../assets/icons/locked.svg`).default} />
                            <div className="notice">
                                <span>You don't have any <b>Server</b> plans</span>
                                <span>You can purchase one below.</span>
                            </div>
                        </>
                        :
                        <>
                            <h1>{getOrders().length}</h1>
                            <div className="notice">
                                <span>Active {getOrders()?.length > 1 ? "servers" : "server"} available.</span>
                                <span>Click <Link to="/dashboard/servers">here</Link> to view your server credentials.</span>
                            </div>
                        </>
                }
            </div>
        </Card>
    )
}

export default ServersCard;