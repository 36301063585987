import api from './api';

class PublicService {

    getAnalytics() {
        return api.get('/public/analytics');
    }

    getPrices() {
        return api.get('/public/prices').then((response) => response.data);
    }

    getStock(id) {
        return api.get('/public/stock/' + id).then((response) => response.data);
    }
}

export default new PublicService();