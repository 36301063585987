class Utils {
    getDaysLeft(date) {
        if (!date)
            return 0;

        var left = Date.parse(date) - new Date().getTime();
        return Math.ceil(left / (1000 * 60 * 60 * 24));
    }
}

export default new Utils();