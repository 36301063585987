import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./LinkModal.scss";
import products from "../../../data/products";
import { useState } from "react";
import adminService from "../../../Services/admin.service";

function LinkModal(props) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [link, setLink] = useState();
    const [loading, setLoading] = useState(false);

    const getProducts = () => {
        return products.proxies.slice().concat(products.servers.slice());
    }

    const onSubmit = (data) => {
        setLoading(true);
        adminService.createLink(data).then((result) => {
            setLink(result.url);
        }).finally(() => {
            setLoading(false);
        })
    };

    return (
        <PopupModal>
            <div className="link-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Create Payment Link</h3>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller name="accountId" control={control} defaultValue={props.id} render={({ field }) => <input {...field} hidden></input>} />
                        <div className="form-item w-100">
                            <label>Account</label>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={props.email}
                                render={({ field }) => <input {...field} readOnly />}
                            />
                        </div>
                        <div className="form-item w-50">
                            <label>Product</label>
                            <Controller
                                name="product"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <select {...field} required>
                                        <option value="" disabled hidden>Select a product</option>
                                        {
                                            getProducts().map(product => (
                                                <option value={product.id}>{product.fullName ?? product.name}</option>
                                            ))
                                        }
                                    </select>
                                )}
                            />
                        </div>
                        <div className="form-item w-50">
                            <label>Quantity</label>
                            <Controller
                                name="quantity"
                                control={control}
                                defaultValue={""}
                                required={true}
                                render={({ field }) => <input {...field} type="number" min={1} max={25000} required placeholder='Enter quantity' />}
                            />
                        </div>
                        <button id="submit-form" type="submit" disabled={loading}>
                            {loading && <div className="loader"></div>}
                            Generate
                        </button>
                    </form>
                    {
                        link && <input className="result-input" value={link}/>
                    }
                </div>
            </div>
        </PopupModal>
    )
}

export default LinkModal;