// ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]);

    const openModal = (modalComponent, modalProps) => {
        setModals((prevModals) => [...prevModals, { component: modalComponent, props: modalProps }]);
        document.documentElement.style.overflow = "hidden";
    };

    const closeModal = () => {
        setModals((prevModals) => prevModals.slice(0, -1));
        document.documentElement.style.overflow = "unset";
    };

    return (
        <ModalContext.Provider value={{ modals, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
};