import api from './api';
import authHeader from './auth-header';

class BlogService {

    getBlogOverview() {
        return api.get('/blog/overview').then((response) => response.data);
    }

    getPost(path) {
        return api.get('/blog/' + path, { headers: authHeader() }).then((response) => response.data);
    }
}

export default new BlogService();