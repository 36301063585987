import { InlineWidget } from "react-calendly";

import "./Calendly.scss";

function Calendly() {
    return (
        <div className="calendly-container">
            <InlineWidget url="https://calendly.com/hello-xlg/introductory-call" styles={{ height: '900px' }} />
        </div>
    );
}

export default Calendly;