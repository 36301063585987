// ModalRoot.js
import React from 'react';
import { useModal } from '../../../Contexts/ModalContext';

const ModalRoot = () => {
  const { modals } = useModal();

  return (
    <div>
      {modals.map((modal, index) => {
        const ModalComponent = modal.component;
        return <ModalComponent key={index} {...modal.props} />;
      })}
    </div>
  );
};

export default ModalRoot;