import { useEffect } from "react";
import { useModal } from "../../../Contexts/ModalContext";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./SuccessModal.scss";
import purchaseService from "../../../Services/purchase.service";

function SuccessModal(props) {
    const { closeModal } = useModal();

    useEffect(() => {

        if (!props.id || props.coinbase)
            return;

        purchaseService.getSessionDetails(props.id).then((data) => {
            gtag('event', 'conversion',
                {
                    'send_to': 'AW-11419894405/74cgCLuxqvsYEIWFt8Uq',
                    'value': data.amountTotal / 100,
                    'currency': 'GBP'
                });
        });

    }, []);

    return (
        <PopupModal>
            <div className="success-modal" tabIndex="-1">
                <div className="modal-header">
                    <h3 className="modal-title">Payment Successful</h3>
                    <p className="modal-subtitle">Order: {props.id}</p>
                </div>
                <div className="modal-body">
                    <img src={require('../../../assets/icons/payment.webp')} />
                    <p>
                        Thank you for your purchase! Your order has been successfully received and is now being processed. You will receive an email confirmation shortly with your order details.
                    </p>
                    {
                        props.coinbase &&
                        <>
                            <br />
                            <p>Cryptocurrency payments for Residential Data take slightly longer to top-up than regular card payments, so please expect your purchase/top-up to arrive in 2-3 minutes.</p>
                        </>
                    }
                    <button id="submit-form" onClick={closeModal}>Continue</button>
                </div>
            </div>
        </PopupModal>
    )
}

export default SuccessModal;